<template>
  <div class="create-perk">
    <ParcelAppbar />
    <div class="ma-4">
      <ParcelCreateForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Parcel
 * ==================================================================================
 **/

import ParcelAppbar from '@/views/Home/Parcel/Parcels/components/ParcelAppbar'
import ParcelCreateForm from '@/views/Home/Parcel/Parcels/components/ParcelCreateForm'

export default {
  components: {
    ParcelAppbar,
    ParcelCreateForm,
  },
}
</script>
<style lang="scss">
.create-perk {
  //
}
</style>
