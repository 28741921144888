import ParcelLocation from '@/models/ParcelLocation'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    parcelLocationDetails: null,
    totalParcelLocations: 0,
    queryParams: null,
    scrollPosition: 0,
  },

  mutations: {
    setParcelLocationList(state, parcelTypes) {
      state.list = parcelTypes
    },

    clearParcelLocationList(state) {
      state.list = []
      state.totalParcelLocations = 0
    },

    setParcelLocationListMeta(state, meta) {
      state.listMeta = meta
    },

    setParcelLocationDetails(state, parcelType) {
      if (parcelType instanceof ParcelLocation) {
        state.parcelLocationDetails = parcelType
      } else {
        state.parcelLocationDetails = new ParcelLocation(parcelType)
      }
    },

    clearParcelLocationDetails(state) {
      state.parcelLocationDetails = null
    },

    setParcelLocationTotals(state, total) {
      state.totalParcelLocations = total
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getParcelLocations({ commit, rootGetters }, params) {
      const query = ParcelLocation.page(params.page || 1).limit(
        params.itemsPerPage || 10
      )
      query.params({ paginate: true })

      if (params.sort) {
        query.orderBy(params.sort)
      }

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }

      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        query.where('building_id', filteredBuilding)
      }

      query.include(['buildings'])

      const res = await query.get()
      commit('setParcelLocationList', res.data)
      commit('setParcelLocationListMeta', res.meta)
      if (get(res, 'meta.total', 0) > 0) {
        commit('setParcelLocationTotals', get(res, 'meta.total', 0))
      }
    },

    async getParcelLocationsByBuilding({ commit }, buildingId) {
      const query = ParcelLocation.params({ paginate: false })
      query.where('building_id', buildingId)
      const res = await query.get()
      return res.data
    },

    async getParcelLocationDetails({ commit }, id) {
      const { data } = await Api.get(`parcelLocations/${id}?include=buildings`)
      commit('setParcelLocationDetails', data.data)
    },

    async createParcelLocation({ commit }, formData) {
      const { data } = await Api.post(`parcelLocations`, formData)
      commit('setParcelLocationDetails', data.data)
    },

    async updateParcelLocation({ commit }, formData) {
      const { data } = await Api.put(`parcelLocations/${formData.id}`, formData)
      commit('setParcelLocationDetails', data.data)
    },
  },
}
