<template>
  <div class="create-parcel-type">
    <ParcelTypeAppbar />
    <div class="ma-4">
      <ParcelTypeForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create ParcelType
 * ==================================================================================
 **/

import ParcelTypeAppbar from '@/views/Home/Parcel/ParcelTypes/components/ParcelTypeAppbar'
import ParcelTypeForm from '@/views/Home/Parcel/ParcelTypes/components/ParcelTypeForm'

export default {
  components: {
    ParcelTypeAppbar,
    ParcelTypeForm,
  },
}
</script>
<style lang="scss"></style>
