import Cookies from 'js-cookie'
import Organisation from '@/models/Organisation'
import Api from '@/services/api'

export default {
  namespaced: true,
  state: {
    organisationDetail: null,
    emailDomains: [],
    orgDomain: null,
  },

  mutations: {
    setOrganisationDetail(state, detail) {
      if (detail instanceof Organisation) {
        state.organisationDetail = detail
      } else {
        state.organisationDetail = new Organisation(detail)
      }
    },

    setEmailDomains(state, domains) {
      state.emailDomains = domains
    },

    setEmailDetails(state, newDomain) {
      state.emailDomains = state.emailDomains.map((domain) =>
        domain.id === newDomain.id ? newDomain : domain
      )
    },

    clearEmailDomains(state) {
      state.emailDomains = []
    },

    setOrgDomain(state, domain) {
      state.orgDomain = domain
    },

    clearOrgDomain(state) {
      state.orgDomain = null
    },
  },

  actions: {
    init({ commit }) {
      const token = Cookies.getJSON('AUTH_TOKEN')
      commit('setToken', token)
    },

    async getOrganisationDetails({ commit }) {
      const { data } = await Api.get('admin/settings/general/organisation')
      commit('setOrganisationDetail', data.data)
    },

    async createOrganisation({ commit }, formData) {
      const { data } = await Api.post('admin/settings/general', formData)
      commit('setOrganisationDetail', data.data)
    },

    async createEmailDomain({ commit }, formData) {
      const { data } = await Api.post('admin/settings/general', formData)
      commit('setEmailDomains', data.data)
    },

    async checkDNSEmail({ commit }, formData) {
      const { data } = await Api.post(`/dnsRecords/${formData.id}/check`)
      if (data.success) {
        commit('setEmailDetails', data.data)
      }
      return data
    },

    async getOrgSetting({ commit }) {
      const { data } = await Api.get('admin/settings/general/email_domain')
      commit('setOrgDomain', data.data)
    },
  },
}
