import Cookies from 'js-cookie'
import router from '@/routes'
import User from '@/models/User'
import { get } from 'lodash'
import ROLE from '@/utils/enums/Role'

export default {
  namespaced: true,
  state: {
    user: {},
    role: ROLE.USER,
    permissions: [],
    isAuthenticated: false,
    token: {
      accessToken: null,
      expiresIn: null,
      tokenType: 'Bearer',
      uuid: null,
    },
    timestamp: new Date().getTime(),
  },

  getters: {
    userId(state) {
      return state.user ? state.user.id : undefined
    },

    avatarThumb(state) {
      return state.user.avatar_thumb_url + '?timestamp=' + state.timestamp
    },
  },

  mutations: {
    setUser(state, user) {
      if (user instanceof User) {
        state.user = user
      } else {
        state.user = new User(user)
      }
    },

    setToken(state, token) {
      if (token) {
        Cookies.set('AUTH_TOKEN', token)
        state.token = token
        state.isAuthenticated = true
      }
    },

    clearToken(state) {
      Cookies.remove('AUTH_TOKEN')
      state.token = {
        accessToken: null,
        expiresIn: null,
        tokenType: 'Bearer',
        uuid: null,
      }
      state.isAuthenticated = false
    },

    changeTimestamp(state) {
      state.timestamp = new Date().getTime()
    },

    setPermissions(state, permissions) {
      state.permissions = permissions
    },

    clearPermissions(state) {
      state.permissions = []
    },

    setRole(state, role) {
      state.role = role
    },
  },

  actions: {
    init({ commit }) {
      const token = Cookies.getJSON('AUTH_TOKEN')
      commit('setToken', token)
    },

    getAuthenticatedUser({ commit }) {
      this.$api
        .get('/auth/me')
        .then((res) => {
          commit('setUser', res.data.data)
          commit('setPermissions', get(res, 'data.data.permissions', []))
          commit('setRole', get(res, 'data.data.role', ROLE.USER))
        })
        // eslint-disable-next-line handle-callback-err
        .catch((err) => {
          commit('clearToken')
          commit('clearPermissions')
          commit('setRole', ROLE.USER)
          router.replace({ name: 'auth.login' })
        })
    },

    logout({ commit }) {
      this.$api.post('/auth/logout').then(() => {
        commit('clearToken')
        commit('clearPermissions')
        commit('setRole', ROLE.USER)
        router.replace({ name: 'auth.login' })
      })
    },

    verifyEmail({ commit }) {
      return this.$api
        .post('/auth/verifyEmailDomain')
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          console.error('API Error:', err)
          throw err
        })
    },
  },
}
