<template>
  <div class="supplier-form">
    <v-form ref="form" class="supplier-form__form" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" md="5">
          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Building(s)</label>
          <SelectMultipleBuildings
            flat
            solo
            required
            class="mt-2"
            :label="'Select buildings'"
            :placeholder="'Select buildings'"
            v-model="form.building_id"
            :pre-select="!isUpdate"
            :error-messages="form.$getError('building_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
            :deletableChips="true"
            :isHasSelectAll="true"
          />

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Company Name</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Company Name"
            v-model="form.company_name"
            :error-messages="form.$getError('company_name')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <label class="text-field-label">Primary Contact</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Contact Name"
            v-model="form.contact_name"
            :error-messages="form.$getError('contact_name')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <label class="text-field-label">ABN</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="ABN"
            v-model="form.abn"
            :error-messages="form.$getError('abn')"
            :loading="form.$busy"
            :disabled="form.$busy"
            :hide-on-start="isUpdate"
          />

          <label class="text-field-label">Address</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Address"
            v-model="form.address"
            :error-messages="form.$getError('address')"
            :loading="form.$busy"
            :disabled="form.$busy"
            :hide-on-start="isUpdate"
          />

          <label class="text-field-label">Phone Number</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Phone Number"
            v-model="form.phone_number"
            :error-messages="form.$getError('phone_number')"
            :loading="form.$busy"
            :disabled="form.$busy"
            :hide-on-start="isUpdate"
          />

          <label class="text-field-label">Mobile Number</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Mobile Number"
            v-model="form.mobile_number"
            :error-messages="form.$getError('mobile_number')"
            :loading="form.$busy"
            :disabled="form.$busy"
            :hide-on-start="isUpdate"
          />

          <label class="text-field-label">Email</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Email"
            v-model="form.email"
            :error-messages="form.$getError('email')"
            :loading="form.$busy"
            :disabled="form.$busy"
            :hide-on-start="isUpdate"
          />

          <label class="text-field-label">Website</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Website"
            v-model="form.website"
            :error-messages="form.$getError('website')"
            :loading="form.$busy"
            :disabled="form.$busy"
            :hide-on-start="isUpdate"
          />

          <label class="text-field-label">Category</label>
          <SelectCategory
            flat
            solo
            class="mt-2"
            v-model="form.category_id"
            :error-messages="form.$getError('category_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
          />

          <label class="text-field-label">Account Code</label>
          <SelectAccount
            flat
            solo
            class="mt-2"
            textColumn="number"
            v-model="form.chart_of_account_id"
            :error-messages="form.$getError('chart_of_account_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
          />

          <label class="text-field-label">Note</label>
          <v-textarea
            flat
            solo
            required
            class="mt-2"
            placeholder="Note"
            v-model="form.note"
            :error-messages="form.$getError('note')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="1" />
        <v-col cols="12" md="6">
          <v-col cols="12">
            <span class="red--text"><strong>* </strong></span>
            <label class="text-field-label">Thumbnail Image</label>
            <SupplierThumbnailImageUpload
              ref="thumbnailUploader"
              :loading="form.$busy"
              :error="form.$getError('thumbnail_image')"
              :for-update="isUpdate"
              @updated="form.$clearError('thumbnail_image')"
              class="my-2"
              :showUploadImageBtn="hasPermissions"
              :form-supplier="form"
            />
            <p class="hint">Ideal image size 460px x 280px</p>
          </v-col>

          <v-col class="table_insurance_data" cols="12">
            <v-row class="row_insurances_table">
              <v-col class="font_weight_700" cols="12" md="6">Insurances</v-col>
              <v-col
                class="button_insurance_table_create text-right"
                cols="12"
                md="6"
              >
                <v-btn
                  v-if="hasPermissions"
                  color="primary"
                  class="appbar__btn"
                  @click="dialogUpdate = true"
                >
                  <v-icon class="mr-2">{{ icons.add }}</v-icon>
                  Add Insurances
                </v-btn>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headerInsurance"
              :items="isUpdate ? insurances : tempInsurances"
              v-model="selected"
              :options.sync="options"
              :footer-props="{
                'items-per-page-options': [5, 10, 15, -1],
              }"
              :items-per-page="5"
              :loading="loading"
              @update:options="fetchInsurances"
              :server-items-length="
                isUpdate ? totalInsurances : tempInsurances.length
              "
            >
              <template v-slot:item.created_at="{ item }">
                <div>
                  {{ item.addedDate }}
                </div>
              </template>
              <template v-slot:item.expiry_date="{ item }">
                <div>
                  {{ item.expiryDate }}
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
              </template>
            </v-data-table>
          </v-col>
          <v-col class="table_insurance_data" cols="12" v-if="isUpdate">
            <v-row class="row_insurances_table">
              <v-col class="font_weight_700" cols="12">Work Orders</v-col>
            </v-row>
            <v-data-table
              :headers="headerWorkOrders"
              :items="workOrders"
              v-model="selected"
              :options.sync="options"
              :footer-props="{
                'items-per-page-options': [5, 10, 15, -1],
              }"
              :items-per-page="5"
              :loading="loading"
              @update:options="fetchWorkOrders"
              :server-items-length="totalWorkOrders"
            >
              <template v-slot:item.status="{ item }">
                <div style="text-align: center">
                  <Status :status="item.statusLabel" />
                </div>
              </template>
              <template v-slot:item.created_at="{ item }">
                <div v-if="item.issueDate">{{ item.issueDate }}</div>
              </template>
              <template v-slot:item.maintenance="{ item }">
                <div v-if="item.dueDate">{{ item.dueDate }}</div>
              </template>
            </v-data-table>
          </v-col>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="5">
          <div class="mt-4">
            <v-btn
              v-if="hasPermissions"
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :loading="form.$busy"
              >{{ buttonLabel }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog v-model="dialogUpdate" max-width="600px">
      <v-form
        ref="formInsurancesFile"
        class="faq-form__form"
        @submit.prevent="submitInsurancesFile"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Open Dialog
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ insuranceHeader }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <label class="text-field-label">File Input</label>
                  <v-file-input
                    v-model="formInsurancesFile.file_input"
                    :loading="formInsurancesFile.$busy"
                    :disabled="formInsurancesFile.$busy"
                    class="mt-2"
                    flat
                    solo
                    clearable
                    show-close="false"
                    :error-messages="
                      isFileTooLarge
                        ? 'The file must be less than 4MB'
                        : formInsurancesFile.$getError('file_input')
                    "
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <label class="text-field-label">Title</label>
                  <v-text-field
                    flat
                    solo
                    required
                    class="mt-2"
                    placeholder="Title"
                    v-model="formInsurancesFile.title"
                    :loading="formInsurancesFile.$busy"
                    :disabled="formInsurancesFile.$busy"
                    :error-messages="formInsurancesFile.$getError('title')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <label class="text-field-label">Expiry</label>
                  <DatetimePicker
                    v-model="formInsurancesFile.expiry_date"
                    flat
                    solo
                    required
                    class="mt-2"
                    type="isoDate"
                    placeholder="Expiry"
                    hide-details="auto"
                    showCalendarIcon
                    withoutPadding
                    :error-messages="
                      formInsurancesFile.$getError('expiry_date')
                    "
                    :loading="formInsurancesFile.$busy"
                    :disabled="formInsurancesFile.$busy"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 "
              text
              @click="cancelDialogUpdate()"
            >
              Cancel
            </v-btn>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 text-uppercase"
              text
              @click="submitInsurancesFile()"
              name="submitFormInsurance"
              :loading="formInsurancesFile.$busy"
              :disabled="
                isFileTooLarge ||
                !formInsurancesFile.title ||
                !formInsurancesFile.file_input
              "
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="600px">
      <v-form
        ref="formInsurancesFile"
        class="faq-form__form"
        @submit.prevent="submitInsurancesFile"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5"
              >Are you sure to delete this insurance(s) ?</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelDialogDelete()">
              No
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="confirmDelete()"
              name="submitFormInsurance"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Perk Form
 * ==================================================================================
 **/
import { mapActions, mapState, mapMutations } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import SelectMultipleBuildings from '@/components/fields/SelectMultipleBuildings'
import SelectCategory from '@/components/fields/SelectCategory'
import SupplierThumbnailImageUpload from '@/views/Home/Maintenance/Suppliers/components/SupplierThumbnailImageUpload.vue'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import { mdiPlus } from '@mdi/js'
import DatetimePicker from '@/components/fields/DatetimePicker.vue'
import Status from '@/components/common/Status.vue'
import SelectAccount from '@/components/fields/SelectAccount.vue'

export default {
  components: {
    SelectAccount,
    Status,
    DatetimePicker,
    SelectMultipleBuildings,
    SelectCategory,
    SupplierThumbnailImageUpload,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    supplier: {
      type: Object,
      default: () => {
        return null
      },
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: new Form({
        building_id: [],
        insurance_ids: [],
        company_name: '',
        contact_name: '',
        abn: '',
        address: '',
        phone_number: '',
        mobile_number: '',
        email: '',
        website: '',
        category_id: null,
        chart_of_account_id: null,
        note: '',
        thumbnail_image: null,
      }),
      formInsurancesFile: new Form({
        file_input: null,
        title: '',
        expiry_date: '',
      }),
      headerInsurance: [
        {
          text: 'Title',
          value: 'title',
        },
        {
          text: 'Added Date',
          value: 'created_at',
        },
        {
          text: 'Expiry Date',
          value: 'expiry_date',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'end',
          sortable: false,
        },
      ],
      headerWorkOrders: [
        {
          text: 'Request ID',
          value: 'maintenance_id',
        },
        {
          text: 'Title',
          value: 'title',
        },
        {
          text: 'status',
          value: 'status',
          sortable: false,
        },
        {
          text: 'Issue Date',
          value: 'created_at',
        },
        {
          text: 'Due Date',
          value: 'maintenance',
          sortable: false,
        },
      ],
      selected: [],
      options: {},
      loading: false,
      isUpdateInsurances: false,
      dialogDelete: false,
      deleteInsuranceId: '',
      dialogUpdate: false,
      icons: {
        add: mdiPlus,
      },
    }
  },

  computed: {
    ...mapState({
      createdSupplier: (state) => state.supplier.supplierDetails,
      permissions: (state) => state.auth.permissions,
      totalInsurances: (state) => state.insurance.totalInsurances,
      insurances: (state) => state.insurance.list,
      workOrders: (state) => state.workOrder.list,
      totalWorkOrders: (state) => state.workOrder.totalWorkOrders,
      createdInsurance: (state) => state.insurance.insuranceDetails,
      errorInsurance: (state) => state.insurance.error,
      tempInsurances: (state) => state.insurance.tempList,
    }),

    header() {
      return this.isUpdate ? 'Supplier Information' : 'Create Supplier'
    },

    insuranceHeader() {
      return this.isUpdateInsurances
        ? 'Edit Insurance details'
        : 'Add Insurance details'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateSupplier : this.createSupplier
    },

    submitApiInsurancesFile() {
      return this.isUpdateInsurances
        ? this.updateInsurance
        : this.createInsurance
    },

    hasPermissions() {
      return validatePermissions(
        [PERMISSION.SUPPLIERS_CREATE],
        this.permissions
      )
    },
    isFileTooLarge() {
      if (this.formInsurancesFile.file) {
        return this.formInsurancesFile.file.size > 4 * 1024 * 1024
      }
      return false
    },
  },

  created() {
    this.clearInsuranceList()
    this.clearTempInsurancesList()
    this.fetchInsurances()
  },

  watch: {
    supplier() {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      createSupplier: 'supplier/createSupplier',
      updateSupplier: 'supplier/updateSupplier',
      getInsurances: 'insurance/getInsurances',
      getWorkOrders: 'workOrder/getWorkOrders',
      createInsurance: 'insurance/createInsurance',
      updateInsurance: 'insurance/updateInsurance',
      deleteInsurance: 'insurance/deleteInsurance',
      clearTempInsurances: 'insurance/clearTempInsurances',
      deleteTempInsurance: 'insurance/deleteTempInsurance',
    }),

    ...mapMutations({
      clearInsuranceList: 'insurance/clearInsuranceList',
      clearTempInsurancesList: 'insurance/clearTempInsurancesList',
    }),

    initForm() {
      if (this.supplier) {
        this.form.building_id = [...this.supplier.buildings].map(
          (item) => item.id
        )
        this.form.company_name = this.supplier.company_name
        this.form.contact_name = this.supplier.contact_name
        this.form.address = this.supplier.address
        this.form.website = this.supplier.website
        this.form.abn = this.supplier.abn
        this.form.phone_number = this.supplier.phone_number
        this.form.mobile_number = this.supplier.mobile_number
        this.form.email = this.supplier.email
        this.form.note = this.supplier.note
        this.form.category_id = this.supplier.category_id
        this.form.thumbnail_image = this.supplier.thumbnail_image
        this.form.chart_of_account_id = this.supplier.chart_of_account_id
      }
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Supplier details successfully updated!')
          } else {
            this.showSnackbar('Supplier successfully created!')
            this.$router.push({
              name: 'maintenance.suppliers',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    async submitInsurancesFile() {
      if (this.formInsurancesFile.$busy) return
      this.formInsurancesFile.$busy = true
      this.formInsurancesFile.$clearErrors()
      let forUpdate = this.isUpdateInsurances
      const formData = await this.getFormInsuranceData()
      const supplierId = this?.supplier?.id
      const id = this?.formInsurancesFile?.id || null
      await this.submitApiInsurancesFile({ formData, supplierId, id })
        .then((res) => {
          if (this.errorInsurance) {
            this.showSnackbar(this.errorInsurance, false)
            this.clearError()
          } else {
            if (forUpdate) {
              this.formInsurancesFile.$busy = false
              this.showSnackbar('Insurance details successfully updated!')
            } else {
              this.showSnackbar('Insurance successfully created!')
            }
          }
          this.isUpdateInsurances = false
          this.dialogUpdate = false
          this.fetchInsurances()
          this.formInsurancesFile = new Form({
            file_input: '',
            expiry_date: '',
            title: '',
          })
        })
        .catch((err) => {
          this.formInsurancesFile.$busy = false
          this.formInsurancesFile.$setErrors(this.getValidationErrors(err))
        })
    },

    async getFormInsuranceData() {
      const data = new Promise((resolve, reject) => {
        let formInsurancesFile = this.formInsurancesFile.$data()
        let file = formInsurancesFile.file_input
        const supplierId = this?.supplier?.id || 0

        if (file instanceof Blob || file instanceof File) {
          const fileReader = new FileReader()
          fileReader.onload = function () {
            let byteArray = new Uint8Array(fileReader.result)
            const blob = new Blob([byteArray], {
              type: 'application/octet-stream',
            })
            const fileName = file.name

            const newFile = new File([blob], fileName, {
              type: blob.type,
              lastModified: Date.now(),
            })

            const formData = new FormData()
            formData.append('file_input', newFile)
            formData.append('supplier_id', supplierId)
            formData.append('title', formInsurancesFile.title)
            formData.append('expiry_date', formInsurancesFile.expiry_date)

            if (this.formInsurancesFile?.id) {
              formData.id = this.formInsurancesFile.id
            }

            resolve(formData)
          }

          fileReader.onerror = function (error) {
            reject(error)
          }

          fileReader.readAsArrayBuffer(file)
        } else {
          // If the file is not a Blob or File, exclude it from FormData
          const formData = new FormData()
          formData.append('title', formInsurancesFile.title)
          formData.append('expiry_date', formInsurancesFile.expiry_date)
          formData.append('supplier_id', supplierId)

          if (this.formInsurancesFile?.id) {
            formData.id = this.formInsurancesFile.id
          }

          resolve(formData)
        }
      })

      return data
    },

    async fetchInsurances(options, page = 1) {
      if (this.loading) return
      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalInsurances
            : options.itemsPerPage
          : 5,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
        supplierId: this.$route?.params.id || null,
      }

      this.loading = true
      await this.getInsurances(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    async fetchWorkOrders(options, page = 1) {
      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalInsurances
            : options.itemsPerPage
          : 5,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
        supplierId: this.$route?.params.id || null,
      }

      this.loading = true
      await this.getWorkOrders(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    editItem(item) {
      this.dialogUpdate = true
      this.isUpdateInsurances = true
      this.formInsurancesFile.title = item.title
      this.formInsurancesFile.file_input = item.file_input
      this.formInsurancesFile.supplier_id = item.supplier_id
      this.formInsurancesFile.expiry_date = item.expiry_date
      this.formInsurancesFile.id = item.id
    },

    cancelDialogDelete() {
      this.dialogDelete = false
    },

    deleteItem(item) {
      this.dialogDelete = true
      this.deleteInsuranceId = item.id
    },

    async confirmDelete() {
      await this.deleteInsurance(this.deleteInsuranceId).then(() => {
        this.deleteTempInsurance(this.deleteInsuranceId)
        this.showSnackbar('Delete insurance successfully!')
        this.dialogDelete = false
        this.fetchInsurances()
      })
    },

    cancelDialogUpdate() {
      this.dialogUpdate = false
      this.isUpdateInsurances = false
      this.formInsurancesFile = new Form({
        file: '',
        title: '',
        expiry_date: '',
      })
    },

    /**
     * Validate form values
     * @return {Boolean}
     */
    validate() {
      this.form.$clearErrors()

      if (this.isUpdate) return true

      return !this.form.$hasErrors()
    },

    getFormData() {
      let form = this.form.$data()
      delete form.thumbnail_image
      form.insurance_ids = this.tempInsurances.map((item) => item.id)

      if (this.isUpdate) {
        form.id = this.supplier.id
        return form
      } else {
        const formData = new FormData()
        for (var field in form) {
          if (Array.isArray(form[field])) {
            form[field].forEach((value, index) => {
              formData.append(`${field}[]`, value)
            })
          } else {
            if (form[field]) {
              formData.append(field, form[field])
            }
          }
        }

        formData.append(
          'thumbnail_image',
          this.$refs.thumbnailUploader.getImage()
        )

        return formData
      }
    },
  },
}
</script>
<style lang="scss">
.supplier-form {
  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }

  .hint {
    font-size: 12px;
    /* Adjust the font size as needed */
    color: #888;
    /* Choose a color for the hint text */
  }
}

.font_weight_700 {
  font-weight: 700;
}
</style>
