const ACTIONS = [
  {
    text: 'Delete',
    value: 'delete',
    permission: false,
  },
  {
    text: 'Approve',
    value: 'approve',
    permission: false,
  },
  {
    text: 'Send to Email',
    value: 'send_to_email',
    permission: false,
  },
  {
    text: 'Mark as Paid',
    value: 'mark_as_paid',
    permission: false,
  },
  {
    text: 'Send to {{accounting}}',
    value: 'send_to_accounting',
    permission: false,
  },
]

export default ACTIONS
