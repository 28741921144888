<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-bullhorn</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Notifications ({{ totalNotifications }})</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-btn
        v-if="hasGroupPermission"
        color="primary"
        class="appbar__btn mx-4"
        height="40px"
        @click="openModalIndex = true"
      >
        Groups
      </v-btn>
      <v-btn
        v-if="hasCreatePermission"
        color="primary"
        class="appbar__btn"
        height="40px"
        :to="{ name: 'notification.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Create Notification
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="notification-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-tabs v-model="defaultStatus">
          <v-tab
            v-for="status in statusTabs"
            :key="status.value"
            :ref="status.value"
            :disabled="defaultStatus !== status.value && loading"
            @click="handleTabClick(status.value)"
          >
            {{ status.text }}
          </v-tab>
        </v-tabs>
        <v-spacer />
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search notification or building"
          @input="search"
        ></v-text-field>
        <!-- <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-10 bulk-btn"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title
                @click="item.permission && actionHandel(item.value)"
                :style="{
                  cursor: item.permission ? 'pointer' : 'not-allowed',
                  color: item.permission ? 'black' : 'gray',
                }"
                >{{ item.label }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu> -->
      </div>
      <v-data-table
        :headers="headers"
        :items="notifications"
        :server-items-length="totalNotifications"
        :loading="loading"
        v-model="selected"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        @click:row="editItem"
        @update:options="fetchNotifications"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        :items-per-page.sync="itemsPerPageTables"
        @pagination="handleScrollAfterTableRender"
      >
        <template v-slot:item.notification="{ item }">
          {{ truncateText(item.notification) }}
        </template>
        <template v-slot:item.is_active="{ item }">
          <v-checkbox v-model="item.is_active" readonly hide-details />
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.addedDate }}
        </template>
        <template v-slot:item.expires_at="{ item }">
          {{ formatedDate(item.expires_at) }}
        </template>
        <template v-slot:item.addedBy="{ item }">
          <div v-if="item.added_name" class="added-by-name">
            {{ item.added_name }}
          </div>
          <div v-if="item.added_email" class="added-by-email">
            {{ item.added_email }}
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small> mdi-delete </v-icon>
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(notifications.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
    <IndexModal :open="openModalIndex" @input="toggle" @show="showCreateForm" />

    <GroupFormModal
      :open="openFormGroups"
      @input="toggleFormGroup"
      :group="group"
    />
    <ConfirmModal
      v-model="showBulkDeleteModal"
      title=""
      :message="messageDelete"
      @cancel="showBulkDeleteModal = false"
      @confirm="
        bulkActions('delete')
        showBulkDeleteModal = false
      "
      confirmText="Yes"
      cancelText="No"
    ></ConfirmModal>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Notification list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import NOTIF_STATUSES from '@/utils/enums/NotifStatus'
import { truncate, messageConfirmDelete } from '@/utils/helpers'
import { dateFormat } from '@/utils/date'
import IndexModal from '@/views/Home/Notifications/groups/IndexModal'
import GroupFormModal from '@/views/Home/Notifications/groups/GroupFormModal'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'NotificationsPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
    IndexModal,
    GroupFormModal,
    ConfirmModal,
  },

  data() {
    return {
      statusTabs: NOTIF_STATUSES,
      loading: false,
      loadingAction: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultFilter: 'created_at',
      defaultStatus: 'active',
      sortOrder: false,
      selected: [],
      filterBy: [
        {
          value: 'expires_at',
          label: 'Expiry date',
        },
        {
          value: 'created_at',
          label: 'Added date',
        },
      ],
      headers: [
        {
          text: 'Notification',
          value: 'notification',
        },
        {
          text: 'Building',
          value: 'buildingName',
          sortable: false,
        },
        {
          text: 'Expiry date',
          value: 'expires_at',
        },
        {
          text: 'Active',
          value: 'is_active',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Added date',
          value: 'created_at',
        },
        {
          text: 'Added By',
          value: 'addedBy',
          sortable: false,
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
          permission: validatePermissions(
            [PERMISSION.NOTIFICATIONS_DELETE],
            this.permissions
          ),
        },
      ],
      openModalIndex: false,
      openFormGroups: false,
      itemsPerPageTables: 25,
      showBulkDeleteModal: false,
    }
  },

  created() {
    this.clearNotifications()
    this.fetchInitParams()
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      notifications: (state) => state.notification.list,
      listMeta: (state) => state.notification.listMeta,
      permissions: (state) => state.auth.permissions,
      totalNotifications: (state) => state.notification.totalNotifications,
      paramsNotification: (state) => state.notification.queryParams,
      scrollPosition: (state) => state.notification.scrollPosition,
      groupDetail: (state) => state.group.groupDetails,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },

    hasCreatePermission() {
      return validatePermissions(
        [PERMISSION.NOTIFICATIONS_CREATE],
        this.permissions
      )
    },

    hasGroupPermission() {
      return validatePermissions([PERMISSION.GROUP_VIEW], this.permissions)
    },

    group() {
      return this.groupDetail
    },

    messageDelete() {
      return messageConfirmDelete(this.selected.length)
    },
  },

  methods: {
    ...mapActions({
      getNotifications: 'notification/getNotifications',
      bulkActionNotification: 'notification/bulkActionNotification',
    }),

    ...mapMutations({
      clearNotifications: 'notification/clearNotificationList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'notification/setQueryParams',
      setScrollPosition: 'notification/setScrollPosition',
      setGroupParams: 'group/setQueryParams',
      setGroupScrollPosition: 'group/setScrollPosition',
    }),

    fetchInitParams() {
      if (this.paramsNotification?.filter?.status) {
        this.defaultStatus = this.paramsNotification.filter.status
      }

      if (this.paramsNotification?.itemsPerPage) {
        const { itemsPerPage } = this.paramsNotification
        this.itemsPerPageTables = [25, 50, 100].includes(itemsPerPage)
          ? itemsPerPage
          : -1
      }

      if (this.paramsNotification?.sort) {
        if (this.paramsNotification.sort[0].includes('-')) {
          this.defaultFilter = this.paramsNotification.sort.slice(1)
          this.sortOrder = true
        } else {
          this.defaultFilter = this.paramsNotification?.sort
          this.sortOrder = false
        }
      } else {
        this.defaultFilter = 'created_at'
      }

      if (this.paramsNotification?.search) {
        this.filter.search = this.paramsNotification.search
      }

      this.fetchNotifications(null, 1, this.paramsNotification)
    },

    filterList(filterValue) {
      this.fetchNotifications(null, filterValue)
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetchNotifications(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.fetchNotifications()
    }, 600),

    async fetchNotifications(options, page = 1, paramsNotification) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalNotifications
            : options.itemsPerPage
          : this.itemsPerPageTables === -1
          ? this.totalNotifications
          : this.itemsPerPageTables,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
        filter: {
          status:
            typeof this.defaultStatus === 'number'
              ? this.statusTabs.find(
                  (item) => item.index === this.defaultStatus
                )?.value
              : this.defaultStatus,
        },
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.clearNotifications()
      this.loading = true
      await this.getNotifications(paramsNotification || params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
          this.scrollToPosition()
        })
      this.setParams(params)
    },

    editItem(item) {
      this.$router.push({
        name: 'notification.details',
        params: { id: item.id },
      })
    },

    actionHandel(action) {
      if (this.selected.length === 0) {
        this.showSnackbar('Please select notifications', false)
        return
      }

      if (action === 'delete') {
        this.showBulkDeleteModal = true
      } else {
        this.bulkActions(action)
      }
    },

    async bulkActions(action) {
      this.loadingAction = true

      const formData = new FormData()
      formData.append('action', action)
      this.selected.forEach((property) => {
        formData.append('ids[]', property.id)
      })

      await this.bulkActionNotification(formData)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.loadingAction = false
        })
        .finally(() => {
          this.loadingAction = false
          this.selected = []
          this.clearNotifications()
          this.fetch()
        })
    },

    handleTabClick(status) {
      this.defaultStatus = status
      this.defaultFilter = 'created_at'
      this.itemsPerPageTables = 25
      this.fetchNotifications()
    },

    truncateText(text) {
      return truncate(text, 50)
    },

    formatedDate(date) {
      return dateFormat(date)
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return
      this.scrollToPosition()
    },

    scrollToPosition() {
      const scrollPosition = this.scrollPosition

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },

    toggle(show = null) {
      const open =
        show === null || typeof show !== 'boolean' ? !this.openModalIndex : show
      this.openModalIndex = open

      if (!open) {
        this.setGroupParams(null)
        this.setGroupScrollPosition(0)
      }
    },

    toggleFormGroup() {
      this.openModalIndex = true
      this.openFormGroups = false
    },

    showCreateForm() {
      this.openModalIndex = false
      this.openFormGroups = true
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input.v-input--checkbox {
  justify-content: center;
  margin: 0;

  .v-input__control {
    width: auto;
    flex-grow: 0;
  }

  .v-input__slot {
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}

::v-deep .main-appbar {
  height: auto !important;
  min-height: 64px;
  .v-toolbar__content {
    flex-wrap: wrap;
    height: auto !important;
    gap: 10px;
  }

  .appbar__btn {
    @media (max-width: 540px) {
      margin-left: 0 !important;
    }
  }
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}

.v-list-item__title {
  cursor: pointer;
}

.added-by-email {
  font-size: 0.7em;
}
</style>
