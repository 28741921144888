import Notification from '@/models/Notification'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    notificationDetails: null,
    totalNotifications: 0,
    statusList: [],
    statusDelivery: null,
    totalNotificationStatus: 0,
    queryParams: null,
    scrollPosition: 0,
  },

  mutations: {
    setNotificationList(state, notifications) {
      state.list = notifications
    },

    clearNotificationList(state) {
      state.list = []
      state.totalNotifications = 0
    },

    setNotificationListMeta(state, meta) {
      state.listMeta = meta
    },

    setNotificationDetails(state, notification) {
      if (notification instanceof Notification) {
        state.notificationDetails = notification
      } else {
        state.notificationDetails = new Notification(notification)
      }
    },

    clearNotificationDetails(state) {
      state.notificationDetails = null
    },

    setTotalNotifications(state, total) {
      state.totalNotifications = total
    },

    setNotificationStatusList(state, status) {
      state.statusList = status
    },

    setTotalNotificationStatus(state, total) {
      state.totalNotificationStatus = total
    },

    clearNotificationStatusList(state) {
      state.statusList = []
      state.totalNotificationStatus = 0
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },

    setStatusDelivery(state, status) {
      state.statusDelivery = status
    },
  },

  actions: {
    async getNotifications({ commit, rootGetters }, params) {
      let res = null

      if (params) {
        const query = Notification.page(params.page || 1).limit(
          params.itemsPerPage || 30
        )
        query.params({ paginate: true })

        if (params.sort) {
          query.orderBy(params.sort)
        }

        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        if (params.filter && params.filter.status) {
          query.where('status', params.filter.status)
        }

        let filteredBuilding = rootGetters['filter/buildingId']
        if (filteredBuilding) {
          query.where('building_id', filteredBuilding)
        }

        query.include(['building'])

        res = await query.get()
      } else {
        res = await Notification.params({ paginate: false }).get()
      }

      commit('setNotificationList', res.data)
      commit('setNotificationListMeta', res.meta)
      if (get(res, 'meta.total', 0) > 0) {
        commit('setTotalNotifications', get(res, 'meta.total', 0))
      }
    },

    async getNotificationDetails({ commit }, id) {
      const { data } = await Api.get(`notifs/${id}?include=profiles`)
      commit('setNotificationDetails', data.data)
    },

    async createNotification({ commit }, formData) {
      const { data } = await Api.post(`notifs`, formData)
      commit('setNotificationDetails', data.data)
    },

    async updateNotification({ commit }, formData) {
      const { data } = await Api.put(`notifs/${formData.id}`, formData)
      commit('setNotificationDetails', data.data)
    },

    async bulkActionNotification({ commit }, formData) {
      await Api.post(`notifs/bulk-action`, formData)
    },

    async getNotificationStatus({ commit }, params) {
      const queryParams = new URLSearchParams({
        page: params.page || 1,
        limit: params.itemsPerPage || 25,
        sort: params.sort || '',
        paginate: true,
      })

      switch (params.filter) {
        case 1:
          queryParams.append('filter[status]', 'delivered')
          break
        case 2:
          queryParams.append('filter[status]', 'undelivered')
          break
        case 3:
          queryParams.append('filter[status]', 'read')
          break
        case 4:
          queryParams.append('filter[status]', 'unread')
          break
      }

      const { data } = await Api.get(
        `notifs/${params.notificationId}/recipients?${queryParams.toString()}`
      )

      commit('setNotificationStatusList', data.data)
      commit('setStatusDelivery', data.data_delivery)
      if (get(data, 'meta.total', 0) > 0) {
        commit('setTotalNotificationStatus', get(data, 'meta.total', 0))
      }
    },
  },
}
