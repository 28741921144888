import Parcel from '@/models/Parcel'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    parcelDetails: null,
    totalParcels: 0,
    queryParams: null,
    scrollPosition: 0,
  },

  mutations: {
    setParcelList(state, parcels) {
      state.list = parcels
    },

    clearParcelList(state) {
      state.list = []
      state.totalParcels = 0
    },

    setParcelListMeta(state, meta) {
      state.listMeta = meta
    },

    setParcelDetails(state, parcel) {
      if (parcel instanceof Parcel) {
        state.parcelDetails = parcel
      } else {
        state.parcelDetails = new Parcel(parcel)
      }
    },

    clearParcelDetails(state) {
      state.parcelDetails = null
    },

    setParcelTotals(state, total) {
      state.totalParcels = total
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getParcels({ commit, rootGetters }, params) {
      const query = Parcel.page(params.page || 1).limit(
        params.itemsPerPage || 25
      )

      query.include([
        'building',
        'property',
        'recipient',
        'addedBy',
        'parcelType',
      ])

      query.params({ paginate: true })

      if (params.sort) {
        query.orderBy(params.sort)
      }

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }

      switch (params.filter) {
        case 0:
          query.where('status', 'pending')
          break
        case 1:
          query.where('status', 'collected')
          break
        default:
          break
      }

      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        query.where('building_id', filteredBuilding)
      }

      const res = await query.get()
      commit('setParcelList', res.data)
      commit('setParcelListMeta', res.meta)
      if (get(res, 'meta.total', 0) > 0) {
        commit('setParcelTotals', get(res, 'meta.total', 0))
      }
    },

    async getParcelDetails({ commit }, id) {
      const { data } = await Api.get(`parcels/${id}`)
      commit('setParcelDetails', data.data)
    },

    async createParcel({ commit }, formData) {
      await Api.post(`parcels`, formData)
    },

    async updateParcel({ commit }, formData) {
      const { data } = await Api.put(`parcels/${formData.id}`, formData)
      commit('setParcelDetails', data.data)
    },

    async bulkActionParcel({ commit }, formData) {
      await Api.post(`parcels/bulkUpdate`, formData)
    },

    async exportToExcel({ commit, rootGetters }, params) {
      const queryParams = new URLSearchParams({
        sort: params.sort || '',
        include: ['building', 'property', 'recipient', 'addedBy', 'parcelType'],
      })

      switch (params.filter) {
        case 0:
          queryParams.append('filter[status]', 'pending')
          break
        case 1:
          queryParams.append('filter[status]', 'collected')
          break
        default:
          break
      }

      if (params.search) {
        queryParams.append('filter[search]', encodeURIComponent(params.search))
      }

      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        queryParams.append('filter[building_id]', filteredBuilding)
      }

      const file = await Api.get(`/parcels/export?${queryParams.toString()}`, {
        responseType: 'blob',
      })
      return file
    },

    async collectedActionParcel({ commit }, id) {
      await Api.post(`parcels/${id}/collected`)
    },

    async sendActionParcel({ commit }, params) {
      await Api.post(`parcels/${params.id}/${params.action}`)
    },
  },
}
