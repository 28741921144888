import Invoice from '@/models/Invoice'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    invoiceDetails: null,
    totalInvoices: 0,
    error: null,
    queryParams: null,
    scrollPosition: 0,
  },

  mutations: {
    setInvoiceList(state, invoices) {
      state.list = invoices
    },

    clearInvoiceList(state) {
      state.list = []
      state.totalInvoices = 0
    },

    setError(state, error) {
      state.error = error
    },

    clearError(state) {
      state.error = null
    },

    setInvoiceListMeta(state, meta) {
      state.listMeta = meta
    },

    setInvoiceDetails(state, invoice) {
      if (state.invoiceDetails) {
        if (!invoice.thumbnail_banner)
          invoice.thumbnail_banner = state.invoiceDetails.thumbnail_banner
      }

      if (invoice instanceof Invoice) {
        state.invoiceDetails = invoice
      } else {
        state.invoiceDetails = new Invoice(invoice)
      }
    },

    clearInvoiceDetails(state) {
      state.invoiceDetails = null
    },

    setInvoiceTotals(state, total) {
      state.totalInvoices = total
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getInvoices({ commit, rootGetters }, params) {
      if (params) {
        const query = Invoice.page(params.page || 1)
          .limit(params.itemsPerPage || 10)
          .orderBy(params.sort)
          .params({ paginate: true })

        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        if (params.maintenanceId) {
          query.where('maintenance_id', params.maintenanceId)
        }

        switch (params.filter) {
          case 0:
            query.where('status', 'new')
            break
          case 1:
            query.where('status', 'approved')
            break
          case 2:
            query.where('trashed', 'only')
            break
          case 3:
            query.where('trashed', 'with')
            break
          default:
            query.where('trashed', 'without')
            break
        }

        // Add building filter if not nil
        let filteredBuilding = rootGetters['filter/buildingId']
        if (filteredBuilding && !params.maintenanceId) {
          query.where('building_id', filteredBuilding)
        }

        query.include([
          'maintenance.building, maintenance.user.property, supplier, file',
        ])

        const res = await query.get()
        commit('setInvoiceList', res.data)
        commit('setInvoiceListMeta', res.meta)
        if (get(res, 'meta.total', 0) > 0) {
          commit('setInvoiceTotals', get(res, 'meta.total', 0))
        }
      } else {
        const res = await Invoice.params({ paginate: false }).get()
        return res.data
      }
    },

    async getInvoiceDetails({ commit }, id) {
      const { data } = await Api.get(
        `invoices/${id}/?include=maintenance,supplier,file`
      )
      commit('setInvoiceDetails', data.data)
    },

    async createInvoice({ commit }, { formData, id }) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await Api.post(`invoices`, formData, config)
      if (data.error) {
        commit('setError', data.error)
      } else {
        commit('setInvoiceDetails', data.data)
      }
    },

    async updateInvoice({ commit }, { formData, id }) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          _method: 'PUT',
        },
      }

      await Api.post(`invoices/${id}/?include=supplier,file`, formData, config)
      // commit('setInvoiceDetails', data.data)
    },

    async deleteInvoice({ commit }, id) {
      await Api.delete(`invoices/${id}`)
    },

    async bulkDeleteInvoice({ commit }, formData) {
      const config = {}

      await Api.post(`invoices/bulk-delete/`, formData, config)
    },

    async bulkApproveInvoice({ commit }, formData) {
      const config = {}

      await Api.post(`invoices/bulk-approve/`, formData, config)
    },

    async bulkMarkAsPaid({ commit }, formData) {
      const config = {}

      await Api.post(`invoices/bulk-paid/`, formData, config)
    },

    async bulkSendToEmail({ commit }, formData) {
      const config = {}

      await Api.post(`invoices/bulk-send-mail/`, formData, config)
    },

    async exportToExcel({ commit, rootGetters }, params) {
      const queryParams = new URLSearchParams({
        include: [
          'maintenance.building',
          'maintenance.user.property',
          'supplier',
          'file',
        ],
        sort: params.sort || '',
      })

      switch (params.filter) {
        case 0:
          queryParams.append('filter[status]', 'new')
          break
        case 1:
          queryParams.append('filter[status]', 'approved')
          break
        case 2:
          queryParams.append('filter[trashed]', 'only')
          break
        case 3:
          queryParams.append('filter[trashed]', 'with')
          break
        default:
          queryParams.append('filter[trashed]', 'without')
          break
      }

      if (params.search) {
        queryParams.append('filter[search]', encodeURIComponent(params.search))
      }

      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        queryParams.append('filter[building_id]', filteredBuilding)
      }

      const file = await Api.get(`/invoices/export?${queryParams.toString()}`, {
        responseType: 'blob',
      })
      return file
    },
  },
}
