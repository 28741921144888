<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn
        class="btn"
        :style="{
          backgroundColor: '#606060',
        }"
        dark
        :color="colorPrimary ? 'primary' : null"
        v-on="on"
        :loading="loading"
        height="40px"
      >
        <v-icon v-if="showIcon" class="mr-1">mdi-chevron-down</v-icon>
        {{ text }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(item, index) in subItems" :key="index">
        <v-list-item-title
          @click="item.permission && $emit('selected', item.value)"
          :style="{
            cursor: item.permission ? 'pointer' : 'not-allowed',
            color: item.permission ? 'black' : 'gray',
          }"
          >{{ item.label }}</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
/**
 * ==================================================================================
 * Bulk action
 * ==================================================================================
 **/

export default {
  props: {
    subItems: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },

    loading: {
      type: Boolean,
      default: false,
    },

    text: {
      type: String,
      default: 'Bulk Actions',
    },

    colorPrimary: {
      type: Boolean,
      default: true,
    },

    showIcon: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss" scoped></style>
