<template>
  <Navbar name="Parcel Types" redirect="parcel.parcel-types" />
</template>
<script>
/**
 * ==================================================================================
 * Parcel Type Appbar
 * ==================================================================================
 **/

import Navbar from '@/components/Navbar'

export default {
  components: {
    Navbar,
  },
}
</script>
