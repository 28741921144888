const ACTION_MESSAGES = {
  sendNoti: 'Alert is sent successfully!',
  sendSms: 'SMS is sent successfully!',
  sendEmail: 'Email is sent successfully!',
  collected: 'Parcel marked as collected successfully!',
}

const ACTIONS = [
  {
    value: 'sendNoti',
    label: 'Send Alert',
    permission: true,
  },
  {
    value: 'sendSms',
    label: 'Send SMS',
    permission: true,
  },
  {
    value: 'sendEmail',
    label: 'Send Email',
    permission: true,
  },
]

export { ACTION_MESSAGES, ACTIONS }
