<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold mt-0"
        >Users ({{ totalUser }})</v-toolbar-title
      >

      <v-spacer></v-spacer>
    </v-app-bar>

    <div
      class="mx-4"
      id="notification-list"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-tabs>
          <v-tab
            v-for="status in statusTabs"
            :key="status.value"
            @change="handleChangeTab(status.value)"
          >
            {{ status.text }}
          </v-tab>
        </v-tabs>
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search user"
          @input="search"
        ></v-text-field>
      </div>
      <v-data-table
        :headers="headers"
        :items="users"
        :server-items-length="totalUser"
        :loading="fetchUser"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        @update:options="fetch"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
      >
        <template v-slot:item.property="{ item }">
          <div>{{ item.property?.name }}</div>
        </template>
        <template v-slot:item.is_in_group="{ item }">
          <div class="d-flex justify-center">
            <v-checkbox
              v-model="item.is_in_group"
              class="mt-0 mb-0 pt-0 check-user"
              @click="toggleAddUser(item.id, item.is_in_group)"
              :disabled="actionUser"
              hide-details="auto"
            />
          </div>
        </template>
      </v-data-table>
      <Loading v-model="actionUser" />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import { sortFilter } from '@/utils/helpers'

export default {
  name: 'GroupUsers',

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  components: {
    Loading,
  },

  props: {
    groupId: {
      type: [Number, String],
      default: null,
    },
  },

  data() {
    return {
      statusTabs: [
        {
          text: 'All',
          value: null,
        },
        {
          text: 'Selected',
          value: 'selected',
        },
      ],
      defaultStatus: null,
      defaultFilter: 'full_name',
      sortOrder: false,
      filter: {
        search: '',
      },
      headers: [
        {
          text: 'Name',
          value: 'full_name',
        },
        {
          text: 'Property',
          value: 'property',
        },
        {
          text: 'Add to group',
          value: 'is_in_group',
          align: 'center',
          sortable: false,
        },
      ],
      loading: false,
      users: [],
      totalUser: 0,
      fetchUser: false,
      actionUser: false,
    }
  },

  created() {
    this.fetch()
  },

  watch: {
    groupId() {
      this.fetch()
    },
  },

  methods: {
    ...mapActions({
      getUsersByGroup: 'group/getUsersByGroup',
      addUserByGroup: 'group/addUserByGroup',
      deleteUserByGroup: 'group/deleteUserByGroup',
    }),

    search: debounce(function () {
      this.fetch()
    }, 600),

    async fetch(options, page = 1) {
      if (this.fetchUser) return
      this.fetchUser = true
      this.users = []

      if (this.groupId) {
        let params = {
          page: options?.page || 1,
          itemsPerPage: options?.itemsPerPage
            ? options?.itemsPerPage === -1
              ? this.totalUser
              : options.itemsPerPage
            : 25,
          filter: this.defaultStatus,
          sort: sortFilter(
            options,
            this.defaultFilter,
            this.sortOrder,
            'full_name'
          ),
          group_id: this.groupId,
        }

        if (this.filter.search) {
          params.search = this.filter.search
        }
        await this.getUsersByGroup(params)
          .then((data) => {
            this.users = data.data.map((item) => ({
              ...item,
              is_in_group: this.defaultStatus ? true : item.is_in_group,
            }))

            this.totalUser = data.meta.total
          })
          .finally(() => {
            this.fetchUser = false
          })
      } else {
        this.fetchUser = false
      }
    },

    async handleUserAction(action, payload, successMessage) {
      if (this.actionUser) return
      this.actionUser = true

      try {
        await action(payload)
        this.showSnackbar(successMessage)
      } catch (error) {
        this.showSnackbar(this.getErrorMessage(error), false)
      } finally {
        this.actionUser = false
        if (this.defaultStatus) {
          setTimeout(this.fetch(), 500)
        }
      }
    },

    async addUser(id) {
      await this.handleUserAction(
        this.addUserByGroup,
        { group_id: this.groupId, id },
        'Added Group User successfully!'
      )
    },

    async removeUser(id) {
      await this.handleUserAction(
        this.deleteUserByGroup,
        { group_id: this.groupId, id },
        'Deleted Group User successfully!'
      )
    },

    toggleAddUser(id, value) {
      value ? this.addUser(id) : this.removeUser(id)
    },

    handleChangeTab(value) {
      this.defaultStatus = value
      this.fetch()
    },
  },
}
</script>
<style lang="scss" scoped>
.main-appbar {
  margin: 0 !important;
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 540px) {
    display: flex;
    flex-direction: column;
  }
  .v-tabs {
    width: fit-content;
  }
}
.check-user {
  .v-input__slot {
    margin-bottom: 0;
  }
}
</style>
