export default [
  {
    path: '/parcel',
    name: 'parcel',
    redirect: { name: 'parcel.dashboard' },
    component: () =>
      import(/* webpackChunkName: "parcels" */ '@/views/Home/Parcel'),
    children: [
      {
        path: 'dashboard',
        name: 'parcel.dashboard',
        meta: {
          requiresPermissions: ['VIEW_PARCEL'],
        },
        component: () =>
          import(
            /* webpackChunkName: "parcels" */ '@/views/Home/Parcel/Parcels'
          ),
      },
      {
        path: 'lists/create',
        name: 'parcel.list.create',
        meta: {
          requiresPermissions: ['CREATE_PARCEL'],
        },
        component: () =>
          import(
            /* webpackChunkName: "parcel.list.create" */ '@/views/Home/Parcel/Parcels/CreateParcel'
          ),
      },
      {
        path: 'lists/:id',
        name: 'parcel.list.details',
        meta: {
          requiresPermissions: ['VIEW_PARCEL'],
        },
        component: () =>
          import(
            /* webpackChunkName: "parcel.details" */ '@/views/Home/Parcel/Parcels/ParcelDetails'
          ),
      },
      {
        path: 'parcel-types',
        name: 'parcel.parcel-types',
        component: () =>
          import(
            /* webpackChunkName: "parcel-types" */ '@/views/Home/Parcel/ParcelTypes'
          ),
      },
      {
        path: 'parcel-types/create',
        name: 'parcel.parcel-type.create',
        meta: {
          requiresPermissions: ['CREATE_PARCEL_TYPE'],
        },
        component: () =>
          import(
            /* webpackChunkName: "parcel-type.create" */ '@/views/Home/Parcel/ParcelTypes/CreateParcelType'
          ),
      },
      {
        path: 'parcel-types/:id',
        name: 'parcel.parcel-type.details',
        meta: {
          requiresPermissions: ['VIEW_PARCEL_TYPE'],
        },
        component: () =>
          import(
            /* webpackChunkName: "parcel-type.details" */ '@/views/Home/Parcel/ParcelTypes/ParcelTypeDetails'
          ),
      },
      {
        path: 'parcel-locations',
        name: 'parcel.parcel-locations',
        meta: {
          requiresPermissions: ['VIEW_PARCEL_LOCATION'],
        },
        component: () =>
          import(
            /* webpackChunkName: "parcel-locations" */
            '@/views/Home/Parcel/ParcelLocations'
          ),
      },
      {
        path: 'parcel-locations/create',
        name: 'parcel.parcel-location.create',
        meta: {
          requiresPermissions: ['CREATE_PARCEL_LOCATION'],
        },
        component: () =>
          import(
            /* webpackChunkName: "parcel-location.create" */
            '@/views/Home/Parcel/ParcelLocations/CreateParcelLocation'
          ),
      },
      {
        path: 'parcel-locations/:id',
        name: 'parcel.parcel-location.details',
        meta: {
          requiresPermissions: ['VIEW_PARCEL_LOCATION'],
        },
        component: () =>
          import(
            /* webpackChunkName: "parcel-location.details" */
            '@/views/Home/Parcel/ParcelLocations/ParcelLocationDetails'
          ),
      },
    ],
  },
]
