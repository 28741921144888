<template>
  <v-dialog
    :value="open"
    @input="
      $emit('input')
      form.$clearErrors()
      clearGroupDetails()
      initForm()
    "
    max-width="1024"
  >
    <v-card>
      <v-card-actions>
        <div class="group-form py-8 px-8">
          <div ref="form" class="group-form__form" @submit.prevent="submit">
            <h3 class="py-5 primary--text">{{ header }}</h3>
          </div>

          <v-row>
            <v-col cols="12" md="4" class="group-field">
              <span class="red--text"><strong>* </strong></span
              ><label class="text-field-label">Building</label>
              <SelectBuilding
                flat
                solo
                required
                class="mt-2"
                v-model="form.building_id"
                :error-messages="form.$getError('building_id')"
                :loading="form.$busy"
                :disabled="form.$busy"
              />

              <span class="red--text"><strong>* </strong></span
              ><label class="text-field-label">Group Name</label>
              <v-text-field
                v-model="form.name"
                flat
                solo
                required
                class="mt-2"
                placeholder="Group Name"
                :error-messages="form.$getError('name')"
                :loading="form.$busy"
                :disabled="form.$busy"
              ></v-text-field>

              <label class="text-field-label">Description</label>
              <v-text-field
                v-model="form.description"
                flat
                solo
                required
                class="mt-2"
                placeholder="Description"
                :error-messages="form.$getError('description')"
                :loading="form.$busy"
                :disabled="form.$busy"
              ></v-text-field>

              <v-checkbox
                v-model="form.is_active"
                hide-details="auto"
                label="Set as active"
                class="mt-0"
              ></v-checkbox>

              <div class="mt-16">
                <v-btn
                  type="submit"
                  color="primary"
                  class="mr-4 px-6"
                  height="40px"
                  width="100%"
                  :loading="form.$busy"
                  @click="submit"
                  >{{ buttonLabel }}</v-btn
                >
              </div>
            </v-col>
            <v-col cols="12" md="8">
              <GroupUsers v-if="isUpdate" :groupId="groupId" />
              <GroupUserCreate
                v-else
                :buildingId="form.building_id"
                ref="groupUsers"
              />
            </v-col>
          </v-row>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * ==================================================================================
 * Group Form Modal
 * ==================================================================================
 **/

import { mapActions, mapMutations } from 'vuex'
import { mdiPlus } from '@mdi/js'
import Form from '@/utils/form'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import SelectBuilding from '@/components/fields/SelectBuilding'
import GroupUsers from '@/views/Home/Notifications/groups/GroupUsers'
import GroupUserCreate from '@/views/Home/Notifications/groups/GroupUserCreate'

export default {
  mixins: [SnackbarMixin, ErrorHandlerMixin],

  components: { SelectBuilding, GroupUsers, GroupUserCreate },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  watch: {
    group(newValue, oldValue) {
      this.initForm()
    },
  },

  data() {
    return {
      form: new Form({
        building_id: '',
        name: '',
        description: '',
        is_active: false,
      }),
      icons: {
        add: mdiPlus,
      },
    }
  },

  computed: {
    header() {
      return this.isUpdate ? 'Edit Group' : 'Create Group'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    isUpdate() {
      return !!this.group
    },

    submitApi() {
      return this.isUpdate ? this.updateGroup : this.createGroup
    },

    groupId() {
      return this.form?.id
    },
  },

  mounted() {
    this.form.$clearErrors()
  },

  destroyed() {
    this.clearGroupDetails()
  },

  created() {
    this.initForm()
  },

  methods: {
    ...mapActions({
      createGroup: 'group/createGroup',
      updateGroup: 'group/updateGroup',
    }),

    ...mapMutations({
      clearGroupDetails: 'group/clearGroupDetails',
    }),

    initForm() {
      if (this.group) {
        this.form.id = this.group.id
        this.form.building_id = this.group.building_id
        this.form.name = this.group.name
        this.form.description = this.group.description
        this.form.is_active = this.group.is_active
      } else {
        this.form = new Form({
          building_id: '',
          name: '',
          description: '',
          is_active: false,
        })
      }
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return
      this.form.$busy = true
      this.form.$clearErrors()

      let forceUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forceUpdate) {
            this.form.$busy = false
            this.showSnackbar('Group details successfully updated!')
          } else {
            this.showSnackbar('Group successfully created!')
            this.$emit('input')
          }
          this.form.$busy = false
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    validate() {
      this.form.$clearErrors()

      if (this.isUpdate) return true

      return !this.form.$hasErrors()
    },

    getFormData() {
      let formData = this.form.$data()

      if (this.isUpdate) {
        formData.id = this.form.id
      } else {
        formData.user_ids = this.$refs.groupUsers.userAddedIds()
      }

      return formData
    },
  },
}
</script>

<style lang="scss">
.group-form {
  width: 100%;

  .group-field {
    margin-top: 64px;

    @media (max-width: 540px) {
      margin-top: 0px;
    }
  }
}
</style>
