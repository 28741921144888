<template>
  <div class="upload-image">
    <v-btn
      v-if="!disabled"
      class="upload-image__delete"
      dark
      fab
      x-small
      color="red"
      @click="deleteImage"
    >
      <v-icon>{{ icons.close }}</v-icon>
    </v-btn>
    <v-img
      v-if="validImage"
      contain
      :src="validImage"
      class="upload-image__image"
      @click="selectImage"
    />
    <p v-else class="upload-image__error">Error loading image</p>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * File Multiple Upload: Upload Image
 * ==================================================================================
 **/

import { mdiClose } from '@mdi/js'

export default {
  props: {
    image: {
      type: Object,
      default: () => {
        return null
      },
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    validImage() {
      return this.image ? this.image.url || this.image.original_url : null
    },
  },

  data() {
    return {
      icons: {
        close: mdiClose,
      },
    }
  },

  methods: {
    selectImage() {
      this.$emit('select-image', this.image)
    },

    deleteImage() {
      this.$emit('delete', this.image)
    },
  },
}
</script>
<style lang="scss" scoped>
.upload-image {
  position: relative;
  width: 50%;
  min-height: 50px;
  border: 1px solid #f1f1f1;
  background-color: #f1f1f1;
  border-radius: 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  &__delete {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 1;
  }

  &__image {
    max-height: 70px;
    border-radius: 12px;
    cursor: pointer;
  }

  &__error {
    font-weight: bold;
    margin-bottom: 0;
    padding: 50px 0;
    color: gray;
  }
}
</style>
