import Model from './Model'
import { dateFormat } from '@/utils/date'

export default class Parcel extends Model {
  resource() {
    return 'parcels'
  }

  get propertyName() {
    if (!this.property) return 'NA'
    return this.property.name
  }

  get buildingName() {
    if (!this.building) return 'NA'
    return this.building.name
  }

  get userName() {
    if (!this.recipient) return 'NA'
    return this.recipient.full_name
  }

  get typeName() {
    if (!this.parcel_type) return 'NA'
    return this.parcel_type.type
  }

  get addByName() {
    if (!this.added_by) return 'NA'
    return this.added_by.full_name
  }

  get addedDate() {
    return dateFormat(this.created_at)
  }

  get notified() {
    return (
      this.send_push_noti === 1 || this.send_email === 1 || this.send_sms === 1
    )
  }
}
