<template>
  <v-select
    class="select-account"
    :value="value"
    :items="validItems"
    :item-text="label"
    :item-value="valueColumn"
    :placeholder="placeholder"
    :loading="loading || fetching"
    :disabled="disabled || fetching"
    v-bind="$attrs"
    @input="onChange"
  />
</template>
<script>
/**
 * ==================================================================================
 * Select Urgency
 * ==================================================================================
 **/
import { mapActions, mapMutations } from 'vuex'

export default {
  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },

    placeholder: {
      type: String,
      default: 'Select account',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    valueColumn: {
      type: String,
      default: 'id',
    },

    textColumn: {
      type: String,
      default: 'name',
    },

    preSelect: {
      type: Boolean,
      default: false,
    },

    preFetch: {
      type: Boolean,
      default: true,
    },

    joinLabel: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    if (this.preFetch) {
      this.fetch()
    }
  },

  data() {
    return {
      fetching: false,
      accounts: [],
    }
  },

  computed: {
    validItems() {
      return this.accounts && this.accounts.length ? this.accounts : []
    },
  },

  methods: {
    ...mapActions({
      getAccounts: 'chartOfAccount/getForSelects',
    }),

    ...mapMutations({
      clearAccounts: 'chartOfAccount/clearChartOfAccountList',
    }),

    label(item) {
      return this.joinLabel
        ? `${item.number} - ${item.name}`
        : `${item[this.textColumn]}`
    },

    async fetch() {
      if (this.fetching) return

      this.fetching = true
      this.clearAccounts()

      let params = {
        filter: {
          buildingId: this.buildingId,
        },
      }

      await this.getAccounts(params)
        .then((data) => {
          this.accounts = data

          if (this.preSelect && this.validItems.length) {
            this.onChange(this.validItems[0].id)
          } else {
            this.onChange(null)
          }

          this.$emit('populated', this.accounts)
        })
        .finally(() => {
          this.fetching = false
        })
    },

    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>
