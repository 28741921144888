import Template from '@/models/Template'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    templateDetails: null,
    mergeFields: [],
    totalTemplates: 0,
    sendWorkOrderTemplate: null,
    sendRequestQuoteTemplate: null,
    queryParams: null,
    scrollPosition: 0,
  },

  mutations: {
    setTemplateList(state, templates) {
      state.list = templates
    },

    clearTemplateList(state) {
      state.list = []
      state.totalTemplates = 0
    },

    setTemplateListMeta(state, meta) {
      state.listMeta = meta
    },

    setTemplateDetails(state, template) {
      if (template instanceof Template) {
        state.templateDetails = template
      } else {
        state.templateDetails = new Template(template)
      }
    },

    setSendWorkOrderTemplate(state, template) {
      if (template instanceof Template) {
        state.sendWorkOrderTemplate = template
      } else {
        state.sendWorkOrderTemplate = new Template(template)
      }
    },

    setSendRequestQuoteTemplate(state, template) {
      if (template instanceof Template) {
        state.sendRequestQuoteTemplate = template
      } else {
        state.sendRequestQuoteTemplate = new Template(template)
      }
    },

    setMergeField(state, mergeFields) {
      state.mergeFields = mergeFields.reduce((acc, item) => {
        // Create a group for each unique object
        if (!acc[item.object]) {
          acc[item.object] = []
        }
        // Push the current item into the relevant group
        acc[item.object].push(item)

        return acc
      }, {})
    },

    clearTemplateDetails(state) {
      state.templateDetails = null
    },

    setTemplateTotals(state, total) {
      state.totalTemplates = total
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getTemplates({ commit, rootGetters }, params) {
      if (params) {
        const query = Template.page(params.page || 1)
          .limit(params.itemsPerPage || 10)
          .orderBy(params.sort)
        query.params({ paginate: true })

        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        // query.include(['user'])

        const res = await query.get()
        commit('setTemplateList', res.data)
        commit('setTemplateListMeta', res.meta)
        if (get(res, 'meta.total', 0) > 0) {
          commit('setTemplateTotals', get(res, 'meta.total', 0))
        }
      } else {
        const res = await Template.params({ paginate: false }).get()
        return res.data
      }
    },

    async getSendWorkOrderTemplate({ commit }) {
      const query = Template.limit(1).where(
        'search',
        encodeURIComponent('Send Work Order')
      )

      const res = await query.get()

      commit('setSendWorkOrderTemplate', res.data[0])
    },

    async getSendRequestQuoteTemplate({ commit }) {
      const query = Template.limit(1).where(
        'search',
        encodeURIComponent('Request Quote')
      )

      const res = await query.get()

      commit('setSendRequestQuoteTemplate', res.data[0])
    },

    async getTemplateDetails({ commit }, id) {
      const { data } = await Api.get(`templates/${id}`)
      commit('setTemplateDetails', data.data)
    },

    async getMergeFields({ commit }) {
      const { data } = await Api.get(`templates/merge-fields`)
      commit('setMergeField', data.data)
    },

    async createTemplate({ commit }, formData) {
      const { data } = await Api.post(`templates`, formData)
      commit('setTemplateDetails', data.data)
    },

    async updateTemplate({ commit }, formData) {
      const { data } = await Api.put(`templates/${formData.id}`, formData)
      commit('setTemplateDetails', data.data)
    },
  },
}
