<template>
  <v-dialog
    :value="open"
    @click:outside="$emit('cancel')"
    @input="$emit('input')"
    max-width="520"
  >
    <v-card class="pa-9">
      <v-card-title class="title pa-0 font-weight-bold">
        {{ header }}
      </v-card-title>
      <v-card-title class="title pa-0 my-4 font-weight-bold">
        {{ name }}
      </v-card-title>
      <div class="booking-form">
        <v-col cols="12 pa-0">
          <v-form ref="form" class="booking-form__form pa-8">
            <v-row>
              <v-col cols="12 pa-0">
                <label class="text-field-label">Property</label>
                <SelectProperty
                  flat
                  solo
                  required
                  class="mt-2"
                  placeholder="Property"
                  v-model="form.property_id"
                  :buildingId="form.building_id"
                  :error-messages="form.$getError('property_id')"
                  :error="form.$hasError('property_id')"
                  :loading="form.$busy"
                  :disabled="form.$busy || isDisabled"
                />
              </v-col>
              <v-col cols="12 pa-0">
                <label class="text-field-label">User</label>
                <SelectUser
                  flat
                  solo
                  required
                  class="mt-2"
                  placeholder="User"
                  v-model="form.user_id"
                  :buildingId="form.building_id"
                  :propertyId="form.property_id"
                  :error-messages="form.$getError('user_id')"
                  :error="form.$hasError('user_id')"
                  :loading="form.$busy"
                  :disabled="form.$busy || isDisabled || isDisabledSelectUser"
                />
              </v-col>
              <v-col cols="12 pa-0">
                <label class="text-field-label">Booking Date</label>
                <DatePicker
                  v-model="form.booking_date"
                  :disableDays="disableDays"
                  :error-messages="
                    form.$hasError('booking_date')
                      ? form.$getError('booking_date')[0]
                      : ''
                  "
                  :error="form.$hasError('booking_date')"
                  :loading="form.$busy"
                  :disabled="form.$busy || isDisabled"
                />
              </v-col>
              <v-col cols="12 pa-0 mt-6">
                <label class="text-field-label">Time Slots</label>
                <SelectTimeSlots
                  flat
                  solo
                  required
                  class="mt-2"
                  v-model="form.time_slots"
                  :loading="form.$busy"
                  :disabled="form.$busy || isDisabledTimeSlots"
                  :multiple="true"
                  :error-messages="form.$getError('booking_date')"
                  :bookableId="bookableId"
                  :bookingDate="form.booking_date"
                />
              </v-col>
              <v-col cols="12 pa-0">
                <label class="text-field-label">Comments</label>
                <v-textarea
                  flat
                  solo
                  required
                  hide-details
                  class="mt-2"
                  placeholder="Comments"
                  v-model="form.comments"
                  :error-messages="form.$getError('comments')"
                  :loading="form.$busy"
                  :disabled="form.$busy || isDisabled"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </div>
      <v-card-actions class="booking-form__buttons">
        <slot>
          <v-btn
            text
            color="blue"
            @click="$emit('cancel')"
            :loading="form.$busy"
            >CANCEL</v-btn
          >
          <v-btn text color="blue" @click="submit" :loading="form.$busy"
            >SAVE</v-btn
          >
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * ==================================================================================
 * Add Booking Modal
 * ==================================================================================
 **/

import { mapActions, mapState } from 'vuex'
import Form from '@/utils/form'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import SelectProperty from '@/components/fields/SelectProperty'
import SelectTimeSlots from '@/components/fields/SelectTimeSlots'
import SelectUser from '@/components/fields/SelectUser'
import { ACCEPTED_BOOKING } from '@/utils/enums/BookingStatus'
import { FILED_REQUIRED } from '@/utils/enums/MessageAlert'
import DatePicker from '@/components/fields/DatePicker.vue'

export default {
  components: {
    SelectProperty,
    SelectUser,
    DatePicker,
    SelectTimeSlots,
  },
  mixins: [SnackbarMixin, ErrorHandlerMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
  },
  watch: {
    'form.property_id': {
      handler(newValue, oldValue) {
        this.resetFormUser()
      },
    },
  },

  data() {
    return {
      form: new Form({
        building_id: '',
        property_id: '',
        user_id: '',
        booking_date: '',
        time_slots: [],
        comments: '',
      }),
    }
  },

  computed: {
    ...mapState({
      bookable: (state) => state.bookable.bookableDetails,
    }),

    header() {
      return 'Add Booking'
    },

    isDisabled() {
      return !!this.bookable.buildingId
    },

    isDisabledSelectUser() {
      return !this.form.property_id
    },

    isDisabledTimeSlots() {
      return !this.form.booking_date
    },

    bookableId() {
      return this.$route.params.id
    },

    disableDays() {
      const openingHours = this.bookable.schedule_settings
      const days = Object.keys(openingHours).filter(
        (day) => openingHours[day].open
      )
      return days
    },
  },

  created() {
    this.initForm()
  },

  methods: {
    ...mapActions({
      createBooking: 'booking/createBooking',
    }),

    initForm() {
      if (this.bookable) {
        this.form.building_id = this.bookable.building_id
      }
    },

    resetFormUser() {
      this.form.user_id = ''
    },

    validate() {
      this.form.$clearErrors()

      if (!this.form.property_id) {
        this.form.$setError('property_id', FILED_REQUIRED)
      }

      if (!this.form.user_id) {
        this.form.$setError('user_id', FILED_REQUIRED)
      }

      if (!this.form.booking_date) {
        this.form.$setError('booking_date', FILED_REQUIRED)
      }

      if (this.form.time_slots.length === 0) {
        this.form.$setError('time_slots', FILED_REQUIRED)
      }

      return !this.form.$hasErrors()
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()

      const dataParams = {
        building_id: this.form.building_id,
        user_id: this.form.user_id,
        bookable_id: this.bookableId,
        bookable_type: 'App\\Models\\Bookable',
        comments: this.form.comments,
        status: ACCEPTED_BOOKING,
        booking_date: this.form.booking_date,
        datetime_start: this.form.booking_date,
        time_slots: this.form.time_slots.map((item) => {
          return {
            end: item.end,
            start: item.start,
          }
        }),
      }
      await this.createBooking(dataParams)
        .then(() => {
          this.showSnackbar('Booking successfully created!')
          this.$emit('cancel')
          this.$emit('load')
        })
        .catch((err) => {
          this.form.$setErrors(this.getValidationErrors(err))
        })
        .finally(() => {
          this.form.$busy = false
        })
    },
  },
}
</script>

<style lang="scss">
.booking-form {
  &__user,
  &__bookable,
  &__building,
  &__form {
    padding: 30px;
  }

  .v-textarea {
    textarea {
      min-height: 120px;
    }
  }

  .v-text-field__details {
    margin-bottom: 0px !important;
  }
}
.booking-form__buttons {
  display: flex;
  justify-content: center;
}
</style>
