<template>
  <div class="parcel-location-form">
    <v-form
      ref="form"
      class="parcel-location-form__form"
      @submit.prevent="submit"
    >
      <h3 class="py-5 primary--text">{{ header }}</h3>

      <v-row>
        <v-col cols="12" md="6">
          <label class="text-field-label">Parcel Location</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Parcel Location"
            v-model="form.location"
            :error-messages="form.$getError('location')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <label class="text-field-label">Building(s)</label>
          <SelectMultipleBuildings
            flat
            solo
            required
            deletableChips
            isHasSelectAll
            class="mt-2"
            v-model="form.building_id"
            :pre-select="!isUpdate"
            :error-messages="form.$getError('building_id')"
            :loading="form.$busy"
            :disabled="form.$busy || disableBuildingField"
            :placeholder="disableBuildingField ? 'All' : 'Select buildings'"
          />
          <v-row>
            <v-col cols="6">
              <v-checkbox
                v-model="form.is_active"
                hide-details="auto"
                label="Set as active"
                class="mt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="form.is_default"
                hide-details="auto"
                label="Set as default"
                class="mt-0"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <div class="mt-4">
            <v-btn
              v-if="hasUpdatePermission"
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :loading="form.$busy"
              >{{ buttonLabel }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Parcel Type Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import SelectMultipleBuildings from '@/components/fields/SelectMultipleBuildings.vue'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  components: {
    SelectMultipleBuildings,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    parcelLocation: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      form: new Form({
        location: '',
        building_id: [],
        is_active: false,
        is_default: false,
      }),
      disableBuildingField: false,
    }
  },

  computed: {
    ...mapState({
      createdParcelLocation: (state) =>
        state.parcelLocation.parcelLocationDetails,
      permissions: (state) => state.auth.permissions,
    }),

    header() {
      return this.isUpdate ? 'Information' : 'Create Parcel Location'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate
        ? this.updateParcelLocation
        : this.createParcelLocation
    },

    isUpdate() {
      return !!this.parcelLocation || !!this.createdParcelLocation
    },

    hasUpdatePermission() {
      return validatePermissions(
        [PERMISSION.PARCEL_LOCATION_CREATE],
        this.permissions
      )
    },
  },

  watch: {
    parcelLocation() {
      this.initForm()
    },
    'form.is_default'(newValue) {
      if (newValue) {
        this.disableBuildingField = true
        this.form.building_id = []
      } else this.disableBuildingField = false
    },
  },

  methods: {
    ...mapActions({
      createParcelLocation: 'parcelLocation/createParcelLocation',
      updateParcelLocation: 'parcelLocation/updateParcelLocation',
    }),

    initForm() {
      if (this.parcelLocation) {
        this.form.location = this.parcelLocation.location
        this.form.building_id = this.parcelLocation.is_default
          ? []
          : this.parcelLocation.buildings.map((item) => item.id)
        this.form.is_active = this.parcelLocation.is_active
        this.form.is_default = this.parcelLocation.is_default
      }
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Parcel location details successfully updated!')
          } else {
            this.showSnackbar('Parcel location successfully created!')
            this.$router.push({
              name: 'parcel.parcel-locations',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    /**
     * Validate form values
     * @return {Boolean}
     */
    validate() {
      this.form.$clearErrors()

      if (this.isUpdate) return true

      return !this.form.$hasErrors()
    },

    getFormData() {
      let form = this.form.$data()
      form.is_active = form.is_active ? 1 : 0
      form.is_default = form.is_default ? 1 : 0

      if (this.isUpdate) {
        form.id = this.parcelLocation.id
      }
      return form
    },
  },
}
</script>
<style lang="scss">
.parcel-location-form {
  &__form {
    max-width: 1000px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }
  .hint {
    font-size: 12px; /* Adjust the font size as needed */
    color: #888; /* Choose a color for the hint text */
  }
}
</style>
