import Model from './Model'
import { dateFormat } from '@/utils/date'

export default class ParcelLocation extends Model {
  resource() {
    return 'parcelLocations'
  }

  get buildingName() {
    const maxLength = 35

    if (!this.buildings || this.buildings.length === 0) return 'NA'
    if (this.is_default) return 'All'

    const names = this.buildings.map((item) => item.name)
    let result = names.join(', ')

    if (result.length > maxLength) {
      result = result.substring(0, maxLength) + '...'
    }

    return result
  }

  get addedDate() {
    return dateFormat(this.created_at)
  }

  get updatedDate() {
    return dateFormat(this.updated_at)
  }
}
