import ParcelType from '@/models/ParcelType'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    parcelTypeDetails: null,
    totalParcelTypes: 0,
    queryParams: null,
    scrollPosition: 0,
  },

  mutations: {
    setParcelTypeList(state, parcelTypes) {
      state.list = parcelTypes
    },

    clearParcelTypeList(state) {
      state.list = []
      state.totalParcelTypes = 0
    },

    setParcelTypeListMeta(state, meta) {
      state.listMeta = meta
    },

    setParcelTypeDetails(state, parcelType) {
      if (parcelType instanceof ParcelType) {
        state.parcelTypeDetails = parcelType
      } else {
        state.parcelTypeDetails = new ParcelType(parcelType)
      }
    },

    clearParcelTypeDetails(state) {
      state.parcelTypeDetails = null
    },

    setParcelTypeTotals(state, total) {
      state.totalParcelTypes = total
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getParcelTypes({ commit, rootGetters }, params) {
      const query = ParcelType.page(params.page || 1).limit(
        params.itemsPerPage || 25
      )
      query.params({ paginate: true })

      if (params.sort) {
        query.orderBy(params.sort)
      }

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }

      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        query.where('building_id', filteredBuilding)
      }

      query.include(['buildings'])
      const res = await query.get()
      commit('setParcelTypeList', res.data)
      commit('setParcelTypeListMeta', res.meta)
      if (get(res, 'meta.total', 0) > 0) {
        commit('setParcelTypeTotals', get(res, 'meta.total', 0))
      }
    },

    async getParcelTypesByBuilding({ commit }, buildingId) {
      const query = ParcelType.params({ paginate: false })
      query.where('building_id', buildingId)
      const res = await query.get()
      return res.data
    },

    async getParcelTypeDetails({ commit }, id) {
      const { data } = await Api.get(`parcelTypes/${id}?include=buildings`)
      commit('setParcelTypeDetails', data.data)
    },

    async createParcelType({ commit }, formData) {
      const { data } = await Api.post(`parcelTypes`, formData)
      commit('setParcelTypeDetails', data.data)
    },

    async updateParcelType({ commit }, formData) {
      const { data } = await Api.put(`parcelTypes/${formData.id}`, formData)
      commit('setParcelTypeDetails', data.data)
    },
  },
}
