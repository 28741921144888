<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-account</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Users ({{ totalUsers }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        v-if="hasCreatePermission"
        color="primary"
        class="appbar__btn"
        height="40px"
        right
        :to="{ name: 'user.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Add User
      </v-btn>

      <v-tooltip>
        <template v-slot:activator="{ on }">
          <v-btn
            color="#383d3f"
            class="mr-1"
            icon
            right
            @click="exportList"
            v-on="on"
            :disabled="loading"
          >
            <v-icon>mdi-export-variant</v-icon>
          </v-btn>
        </template>
        <span>Export to Excel</span>
      </v-tooltip>
    </v-app-bar>

    <div
      class="mx-4"
      id="user-list"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-tabs v-model="defaultStatus">
          <v-tab
            class="pa-3"
            v-for="item in tabItems"
            :key="item.value"
            :ref="item.value"
            :disabled="defaultStatus !== item.value && loading"
            @click="handleTabClick(item.value)"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>

        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search name, email or phone number"
          @input="search"
        ></v-text-field>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-10 bulk-btn"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              :loading="loadingAction"
              height="40px"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in actionsWithPermissions"
              :key="index"
            >
              <v-list-item-title
                @click="item.permission && actionHandel(item.value)"
                :style="{
                  cursor: item.permission ? 'pointer' : 'not-allowed',
                  color: item.permission ? 'black' : 'gray',
                }"
                >{{ item.label }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-data-table
        :headers="headers"
        :items="users"
        :server-items-length="totalUsers"
        :loading="loading"
        v-model="selected"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        show-select
        @click:row="editItem"
        @update:options="fetch"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        :hide-default-footer="loading"
        :items-per-page.sync="itemsPerPageTables"
        @pagination="handleScrollAfterTableRender"
      >
        <template v-slot:item.full_name="{ item }">
          <user-avatar :avatar="item.avatar" size="40" class="mr-4" />
          {{ item.full_name }}
        </template>
        <template v-slot:item.property="{ item }">
          {{ item?.property?.name }}
        </template>
        <template v-slot:item.onboarded_at="{ item }">
          <v-checkbox
            disabled
            v-model="item.isOnboarded"
            readonly
            hide-details
          />
        </template>
        <template v-slot:item.building="{ item }">
          {{ item.buildingName }}
        </template>
        <template v-slot:item.blocked_at="{ item }">
          <div style="text-align: center">
            <Status :status="item.statusLabel" />
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.addedDate }}
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(users.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
    <ConfirmModal
      v-model="showBulkDeleteModal"
      title=""
      :message="messageDelete"
      @cancel="showBulkDeleteModal = false"
      @confirm="
        bulkActions('delete')
        showBulkDeleteModal = false
      "
      confirmText="Yes"
      cancelText="No"
    ></ConfirmModal>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * User list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiFileExcel, mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import UserAvatar from '@/components/UserAvatar'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import Status from '@/components/common/Status'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import {
  sortFilter,
  perPageFilter,
  messageConfirmDelete,
} from '@/utils/helpers'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'UsersPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    UserAvatar,
    AppBarNavIcon,
    Status,
    ConfirmModal,
  },

  data() {
    return {
      selected: [],
      itemActions: [{ title: 'Delete' }],
      headers: [
        {
          text: 'Name',
          value: 'full_name',
          width: '17%',
        },
        {
          text: 'Property',
          value: 'property',
          width: '10%',
        },
        {
          text: 'Email',
          value: 'email',
          width: '13%',
        },
        {
          text: 'Phone Number',
          value: 'phone_number',
          width: '13%',
          align: 'center',
        },
        {
          text: 'Building',
          value: 'building',
          width: '13%',
        },
        {
          text: 'Onboarded',
          value: 'onboarded_at',
          width: '9%',
          align: 'center',
        },
        {
          text: 'Status',
          value: 'blocked_at',
          width: '10%',
          align: 'center',
        },
        {
          text: 'Added date',
          value: 'created_at',
          width: '13%',
        },
      ],
      loading: false,
      loadingAction: false,
      filter: {
        search: '',
      },
      icons: {
        export: mdiFileExcel,
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultStatus: 'active',
      defaultAction: '',
      defaultFilter: 'full_name',
      sortOrder: false,
      tabItems: [
        { tab: 'Active', value: 'active', index: 0 },
        { tab: 'Deactivated', value: 'blocked', index: 1 },
        { tab: 'All', value: 'all', index: 2 },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
          permission: false,
        },
      ],
      itemsPerPageTables: 25,
      exportParams: null,
      showBulkDeleteModal: false,
    }
  },

  created() {
    this.fetchInitParams()
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      users: (state) => state.user.list,
      listMeta: (state) => state.user.listMeta,
      permissions: (state) => state.auth.permissions,
      totalUsers: (state) => state.user.totalUsers,
      paramsUsers: (state) => state.user.queryParams,
      scrollPosition: (state) => state.user.scrollPosition,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },

    hasCreatePermission() {
      return validatePermissions([PERMISSION.USERS_CREATE], this.permissions)
    },

    messageDelete() {
      return messageConfirmDelete(this.selected.length)
    },

    actionsWithPermissions() {
      return this.actions.map((action) => ({
        ...action,
        permission: this.bulkPermission(action.value),
      }))
    },
  },

  methods: {
    ...mapActions({
      getUsers: 'user/getUsers',
      bulkActionUser: 'user/bulkActionUser',
      exportToExcel: 'user/exportToExcel',
    }),

    ...mapMutations({
      clearUsers: 'user/clearUserList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'user/setQueryParams',
      setScrollPosition: 'user/setScrollPosition',
    }),

    fetchInitParams() {
      if (this.paramsUsers?.filter) {
        this.defaultStatus = this.paramsUsers.filter
      }

      if (this.paramsUsers?.itemsPerPage) {
        const { itemsPerPage } = this.paramsUsers
        this.itemsPerPageTables = [25, 50, 100].includes(itemsPerPage)
          ? itemsPerPage
          : -1
      }

      if (this.paramsUsers?.sort) {
        if (this.paramsUsers.sort[0].includes('-')) {
          this.defaultFilter = this.paramsUsers.sort.slice(1)
          this.sortOrder = true
        } else {
          this.defaultFilter = this.paramsUsers?.sort
          this.sortOrder = false
        }
      } else {
        this.defaultFilter = 'full_name'
      }

      if (this.paramsUsers?.search) {
        this.filter.search = this.paramsUsers.search
      }

      this.fetch(null, 1, this.paramsUsers)
    },

    filterList(filterValue) {
      this.fetch(null, filterValue)
    },

    handleTabClick(status) {
      this.defaultStatus = status
      this.defaultFilter = 'full_name'
      this.itemsPerPageTables = 25
      this.fetch()
    },

    search: debounce(function () {
      this.fetch()
    }, 600),

    async fetch(options, page = 1, paramsUsers = null) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        filter:
          typeof this.defaultStatus === 'number'
            ? this.tabItems.find((item) => item.index === this.defaultStatus)
                ?.value
            : this.defaultStatus,
        sort: sortFilter(
          options,
          this.defaultFilter,
          this.sortOrder,
          'full_name'
        ),
        itemsPerPage: perPageFilter(
          options,
          this.totalUsers,
          this.itemsPerPageTables
        ),
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }
      this.loading = true
      this.clearUsers()
      await this.getUsers(paramsUsers || params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
          this.scrollToPosition()
        })
      this.setParams(params)
      this.exportParams = paramsUsers || params
    },

    async exportList() {
      this.setLoadingBuilding(true)
      this.loading = true

      await this.exportToExcel(this.exportParams)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'users_list.xlsx')
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('File successfully downloaded')
          this.setLoadingBuilding(false)
        })
        .catch((err) => {
          this.showSnackbar(
            this.getErrorMessage(err, 'Oops! Something went wrong'),
            false
          )
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },

    editItem(item) {
      this.$router.push({
        name: 'user.details',
        params: { id: item.id },
      })
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return
      this.scrollToPosition()
    },

    scrollToPosition() {
      const scrollPosition = this.scrollPosition

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },

    actionHandel(action) {
      if (this.selected.length === 0) {
        this.showSnackbar('Please select users', false)
        return
      }

      if (action === 'delete') {
        this.showBulkDeleteModal = true
      } else {
        this.bulkActions(action)
      }
    },

    async bulkActions(action) {
      this.loadingAction = true

      const formData = new FormData()
      formData.append('action', action)
      this.selected.forEach((property) => {
        formData.append('ids[]', property.id)
      })

      await this.bulkActionUser(formData)
        .then(() => {
          this.showSnackbar('Bulk successfully updated!')
        })
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.loadingAction = false
        })
        .finally(() => {
          this.loadingAction = false
          this.selected = []
          this.clearUsers()
          this.fetch()
        })
    },

    bulkPermission(value) {
      let allowAction = false
      switch (value) {
        case 'delete':
          allowAction = validatePermissions(
            [PERMISSION.USERS_DELETE],
            this.permissions
          )
          break
        default:
          break
      }
      return allowAction
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input.v-input--checkbox {
  justify-content: center;
  margin: 0;

  .v-input__control {
    width: auto;
    flex-grow: 0;
  }

  .v-input__slot {
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}

.v-list-item__title {
  cursor: pointer;
}

::v-deep .main-appbar {
  height: auto !important;
  min-height: 64px;
  .v-toolbar__content {
    flex-wrap: wrap;
    height: auto !important;
    gap: 10px;
  }

  .appbar__btn {
    @media (max-width: 540px) {
      margin-left: 0 !important;
    }
  }
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  &__status {
    width: 100%;
    max-width: 200px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }

    &__status {
      max-width: inherit;
      margin-bottom: 15px;
      margin-left: 0;
    }
  }
}
</style>
