<template>
  <div class="notification-status">
    <div class="notification-status__delivery">
      <h3>Delivery</h3>
      <div class="notification-status__delivery-wrapper py-8">
        <div v-for="(item, index) in deliveries" :key="index">
          <div class="notification-status__delivery-type">
            <div class="mail">
              <v-icon class="text-light-blue icon">{{ item.icon }}</v-icon>
            </div>
            <div class="information">
              <h1>
                {{
                  item.text === 'Read' ? readPercentage : deliveryPercentage
                }}%
              </h1>
              <h4>{{ item.text }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="notification-status__detail">
      <h3>Notification Status</h3>

      <v-tabs v-model="currentTab" hide-slider>
        <v-tab
          v-for="status in tabStatusItems"
          :key="status.value"
          :ref="status.value"
          @change="changeTab(status.value)"
        >
          {{ status.text }}
        </v-tab>
      </v-tabs>
      <v-data-table
        :headers="headers"
        :items="status"
        :server-items-length="totalNotificationStatus"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        @update:options="fetch"
      >
        <template align:center v-slot:item.recipient="{ item }">
          {{ item.user?.full_name }}
        </template>
        <template align:center v-slot:item.delivery="{ item }">
          <Status :status="item.status" />
        </template>
        <template align:center v-slot:item.read="{ item }">
          <Status
            :status="
              item.status === 'undelivered' && item.read_at ? 'read' : 'unread'
            "
          />
        </template>
      </v-data-table>
    </div>
    <Loading :value="loading" />
  </div>
</template>

<script>
/**
 * ==================================================================================
 * View Notification Status
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Status from '@/components/common/Status'
import Loading from '@/components/common/Loading'
import { mdiEmailCheck, mdiEyeCheck } from '@mdi/js'

export default {
  name: 'NotificationStatus',

  components: { Status, Loading },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    notificationId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
      currentTab: 0,
      defaultFilter: '',
      sortOrder: false,
      tabStatusItems: [
        { text: 'All', value: 0 },
        { text: 'DELIVERED', value: 1 },
        { text: 'UNDELIVERED', value: 2 },
        { text: 'READ', value: 3 },
        { text: 'UNREAD', value: 4 },
      ],
      headers: [
        {
          text: 'Recipient',
          value: 'recipient',
          sortable: false,
        },
        {
          text: 'Delivered',
          value: 'delivery',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Read',
          value: 'read',
          sortable: false,
          align: 'center',
        },
      ],
      deliveries: [
        { text: 'Delivery', icon: mdiEmailCheck },
        { text: 'Read', icon: mdiEyeCheck },
      ],
    }
  },

  computed: {
    ...mapState({
      status: (state) => state.notification.statusList,
      statusDelivery: (state) => state.notification.statusDelivery,
      totalNotificationStatus: (state) =>
        state.notification.totalNotificationStatus,
    }),

    deliveryPercentage() {
      return this.statusDelivery ? this.statusDelivery.percent_delivered : 0
    },

    readPercentage() {
      return this.statusDelivery ? this.statusDelivery.percent_read : 0
    },
  },

  methods: {
    ...mapActions({
      getNotificationStatus: 'notification/getNotificationStatus',
    }),

    ...mapMutations({
      clearStatus: 'notification/clearNotificationStatusList',
    }),

    changeTab(tab) {
      this.currentTab = tab
      this.fetch()
    },

    async fetch(options, page = 1) {
      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalNotificationStatus
            : options.itemsPerPage
          : 25,
        filter: this.currentTab,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
        notificationId: this.notificationId,
      }

      this.clearStatus()
      this.loading = true
      await this.getNotificationStatus(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.notification-status {
  max-width: 600px;
  margin-top: 45px;
  overflow-y: auto;

  @media (max-width: 768px) {
    max-width: 100%;
  }

  &__delivery-wrapper {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  &__delivery-type {
    padding: 15px;
    border-radius: 10px;
    width: 250px;
    padding-bottom: 30px;
    background-color: var(--v-grey-lighten3);
    .mail {
      height: 40px;
      width: 40px;
      background-color: white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        height: 25px;
        width: 25px;
      }
    }
    .information {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      h1 {
        font-size: 50px;
        font-weight: 700;
        font-family: sans-serif;
        line-height: 50px;
      }
    }
  }
}
</style>
