import Vue from 'vue'
import Router from 'vue-router'
import auth from './auth'
import users from './users'
import buildings from './buildings'
import bookables from './bookables'
import bookings from './bookings'
import maintenance from './maintenance'
import hub from './hub'
import settings from './settings'
import notifications from './notifications'
import perks from './perks'
import events from './events'
import news from './news'
import gallery from './gallery'
import teams from './teams'
import properties from './properties'
import featureProfile from './featureProfile'
import home from './home'
import lobbyTv from './lobby-tv'
import parcels from './parcels'
Vue.use(Router)

var router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    auth,
    {
      path: '/',
      name: 'home',
      redirect: { name: 'home' },
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
      children: [
        ...users,
        ...buildings,
        ...bookables,
        ...bookings,
        ...maintenance,
        ...hub,
        ...notifications,
        ...perks,
        ...events,
        ...news,
        ...gallery,
        ...teams,
        ...featureProfile,
        ...home,
        ...lobbyTv,
        ...properties,
        ...parcels,
        settings,
      ],
    },
  ],
})

export default router
