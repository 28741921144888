<template>
  <div class="parcel-type-details py-8">
    <ParcelTypeAppbar />

    <div class="ma-4">
      <ViewingTitle :title="displayName" />
      <DeleteOrRestore
        v-if="hasDeletePermission"
        :model="parcelType"
        name="parcel type"
        redirect="/parcel/parcel-types"
      />

      <div class="d-flex mb-6">
        <v-tabs hide-slider class="parcel-type-info-tabs">
          <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
            {{ item.tab }}
          </v-tab>

          <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
            <v-divider class="parcel-type-details__divider"></v-divider>
            <ParcelTypeForm :parcel-type="parcelType" />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>

    <Loading :value="loading" />
  </div>
</template>

<script>
/**
 * ==================================================================================
 * View Parcel Type
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import ParcelTypeAppbar from '@/views/Home/Parcel/ParcelTypes/components/ParcelTypeAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import ParcelTypeForm from '@/views/Home/Parcel/ParcelTypes/components/ParcelTypeForm'
import { access } from '@/utils/accessBuilding'

export default {
  name: 'UserDetails',

  components: {
    ViewingTitle,
    ParcelTypeAppbar,
    Loading,
    DeleteOrRestore,
    ParcelTypeForm,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      parcelType: (state) => state.parcelType.parcelTypeDetails,
      permissions: (state) => state.auth.permissions,
      buildingList: (state) => state.filter.buildingList,
    }),

    ...mapGetters({
      loggedInUserId: 'auth/userId',
    }),

    displayName() {
      return this.parcelType ? this.parcelType.type : null
    },

    hasDeletePermission() {
      return (
        validatePermissions(
          [PERMISSION.PARCEL_TYPE_DELETE],
          this.permissions
        ) && this.accessBuilding
      )
    },

    accessBuilding() {
      return access(this.parcelType, this.buildingList)
    },
  },

  methods: {
    ...mapActions({
      getParcelTypeDetails: 'parcelType/getParcelTypeDetails',
    }),

    ...mapMutations({
      clearParcelTypeDetails: 'parcelType/clearParcelTypeDetails',
    }),

    async getDetail() {
      this.loading = true
      await this.getParcelTypeDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getDetail()
  },

  destroyed() {
    this.clearParcelTypeDetails()
  },

  watch: {
    $route() {
      this.getDetail()
    },
  },
}
</script>

<style lang="scss" scoped>
.parcel-type-details {
  &__divider {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
