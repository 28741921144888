import Supplier from '@/models/Supplier'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    supplierDetails: null,
    totalSuppliers: 0,
    queryParams: 0,
    scrollPosition: 0,
  },

  mutations: {
    setSupplierList(state, suppliers) {
      state.list = suppliers
    },

    clearSupplierList(state) {
      state.list = []
      state.totalSuppliers = 0
    },

    setSupplierListMeta(state, meta) {
      state.listMeta = meta
    },

    setSupplierDetails(state, supplier) {
      if (state.supplierDetails) {
        if (!supplier.thumbnail_banner)
          supplier.thumbnail_banner = state.supplierDetails.thumbnail_banner
      }

      if (supplier instanceof Supplier) {
        state.supplierDetails = supplier
      } else {
        state.supplierDetails = new Supplier(supplier)
      }
    },

    setSupplierImage(state, payload) {
      if (state.supplierDetails) {
        state.supplierDetails[payload.image_name] = payload.image
      }
    },

    clearSupplierDetails(state) {
      state.supplierDetails = null
    },

    setSupplierTotals(state, total) {
      state.totalSuppliers = total
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getSuppliers({ commit, rootGetters }, params) {
      if (params) {
        const query = Supplier.page(params.page || 1)
          .limit(params.itemsPerPage || 25)
          .params({ paginate: true })
          .where('is_archived', params.status === 1)

        if (params.sort) {
          const sort = params.sort
            .replace('insuranceExpiry', 'insurance_expiry')
            .replace('categoryName', 'category')
          query.orderBy(sort)
        }

        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        query.include(['buildings, category, insurances'])

        // Add building filter if not nil
        let filteredBuilding = rootGetters['filter/buildingId']
        if (filteredBuilding) {
          query.where('building_id', filteredBuilding)
        }

        const res = await query.get()
        commit('setSupplierList', res.data)
        commit('setSupplierListMeta', res.meta)
        if (get(res, 'meta.total', 0) > 0) {
          commit('setSupplierTotals', get(res, 'meta.total', 0))
        }
      } else {
        const res = await Supplier.params({ paginate: false }).get()
        return res.data
      }
    },

    async getSupplierDetails({ commit }, id) {
      const { data } = await Api.get(
        `suppliers/${id}?include=buildings,insurances,thumbnail_image`
      )
      commit('setSupplierDetails', data.data)
    },

    async createSupplier({ commit }, formData) {
      const { data } = await Api.post(`suppliers`, formData)
      commit('setSupplierDetails', data.data)
    },

    async updateSupplier({ commit }, formData) {
      const { data } = await Api.put(`suppliers/${formData.id}`, formData)
      commit('setSupplierDetails', data.data)
    },

    async archiveSupplier({ commit }, { id, formData }) {
      const { data } = await Api.post(
        `suppliers/${id}/archive/?_method=PUT`,
        formData
      )
      return data.data
    },

    async uploadSupplierImage({ commit }, formData) {
      const { data } = await Api.post(
        /**
         * NOTE: As FormData doesn't work w/ `PUT` requests
         * add it in manually

         * https://stackoverflow.com/questions/50691938/patch-and-put-request-does-not-working-with-form-data
         */
        `suppliers/${formData.get('id')}?_method=PUT`,
        formData
      )

      commit('setSupplierDetails', data.data)
    },

    async deleteSupplierImage({ commit }, formData) {
      await Api.delete(`media/${formData.id}`, formData)
      commit('setSupplierImage', {
        image: null,
        image_name: formData.image_name,
      })
    },
    async exportToExcel({ commit, rootGetters }, params) {
      const queryParams = new URLSearchParams({
        include: ['buildings', 'category', 'insurances'],
      })
      if (params.sort) {
        const sort = params.sort
          .replace('insuranceExpiry', 'insurance_expiry')
          .replace('categoryName', 'category')
        queryParams.append('sort', sort)
      }

      queryParams.append('filter[is_archived]', params.status === 1)

      if (params.search) {
        queryParams.append('filter[search]', encodeURIComponent(params.search))
      }

      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        queryParams.append('filter[building_id]', filteredBuilding)
      }

      const file = await Api.get(
        `/suppliers/export?${queryParams.toString()}`,
        {
          responseType: 'blob',
        }
      )
      return file
    },
  },
}
