import Group from '@/models/Group'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    groupDetails: null,
    totalGroups: 0,
    users: {
      list: [],
      listMeta: {
        current_page: 0,
        last_page: 1,
      },
    },
    queryParams: null,
    scrollPosition: 0,
  },

  mutations: {
    setGroupList(state, groups) {
      state.list = groups
    },

    clearGroupList(state) {
      state.list = []
      state.totalGroups = 0
    },

    setGroupListMeta(state, meta) {
      state.listMeta = meta
    },

    setGroupDetails(state, group) {
      if (group instanceof Group) {
        state.groupDetails = group
      } else {
        state.groupDetails = new Group(group)
      }
    },

    clearGroupDetails(state) {
      state.groupDetails = null
    },

    setGroupTotals(state, total) {
      state.totalGroups = total
    },

    setUserList(state, users) {
      state.users.list = users
    },

    setUserListMeta(state, meta) {
      state.users.listMeta = meta
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getGroups({ commit, rootGetters }, params) {
      let res = null

      if (params.paginate) {
        const query = Group.page(params.page || 1).limit(
          params.itemsPerPage || 25
        )
        query.params({ paginate: true })

        if (params.sort) {
          query.orderBy(params.sort)
        }

        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        let filteredBuilding = rootGetters['filter/buildingId']
        if (filteredBuilding) {
          query.where('building_id', filteredBuilding)
        }

        res = await query.get()

        commit('setGroupList', res.data)
        commit('setGroupListMeta', res.meta)

        if (get(res, 'meta.total', 0) > 0) {
          commit('setGroupTotals', get(res, 'meta.total', 0))
        }
      } else {
        const query = Group.include(['properties']).params({ paginate: false })

        if (params.building_id) {
          query.where('building_id', params.building_id)
        }

        res = await query.get()
        return res.data
      }
    },

    async getGroupDetails({ commit }, id) {
      const { data } = await Api.get(`groups/${id}`)
      commit('setGroupDetails', data.data)
    },

    async createGroup({ commit }, formData) {
      const { data } = await Api.post(`groups`, formData)
      commit('setGroupDetails', data.data)
    },

    async updateGroup({ commit }, formData) {
      const { data } = await Api.put(`groups/${formData.id}`, formData)
      commit('setGroupDetails', data.data)
    },

    async deleteGroup({ commit }, formData) {
      await Api.delete(`groups/${formData.id}`, formData)
    },

    async getUsersByGroup({ commit }, params) {
      const queryParams = new URLSearchParams({
        sort: params.sort || '',
        page: params.page || 1,
        limit: params.itemsPerPage || 5,
        include: ['property'],
        paginate: true,
      })

      if (params.filter) {
        queryParams.append('is_selected', 1)
      }

      if (params?.search) {
        queryParams.append('search', params.search)
      }

      let url = `/groups/${params.group_id}/users?${queryParams.toString()}`

      const { data } = await Api.get(url)

      return data
    },

    async addUserByGroup({ commit }, params) {
      await Api.post(`groups/${params.group_id}/users`, { user_id: params.id })
    },

    async deleteUserByGroup({ commit }, params) {
      await Api.delete(`groups/${params.group_id}/users/${params.id}`)
    },
  },
}
