<template>
  <v-form ref="form" @submit.prevent="submit">
    <v-toolbar-title class="headline font-weight-bold mb-8 mt-16"
      >Email Domain Verification</v-toolbar-title
    >
    <v-row class="padding-bottom-0">
      <v-col cols="12" md="6">
        <label class="text-field-label">Domain</label>
        <v-text-field
          flat
          solo
          class="mt-2"
          placeholder="Domain"
          v-model="form.domain"
          :error-messages="form.$getError('domain')"
          :loading="form.$busy"
          :disabled="form.$busy"
          hide-details="auto"
        ></v-text-field>
        <v-row class="padding-bottom-0">
          <v-col cols="12" md="5">
            <div class="mt-10">
              <v-btn
                type="submit"
                color="primary"
                class="mr-4 px-6"
                height="40px"
                width="100%"
                :loading="form.$busy"
                :disabled="!form.domain"
                >Generate DNS Records</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-8">
        <span class="font-weight-bold"
          >Please add the following DNS records to your DNS provider to
          authenticate your domain</span
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10">
        <v-data-table
          class="custom-table"
          :headers="headers"
          :items="emailDomains"
          :loading="loading"
          hide-default-footer
        >
          <template v-slot:item.host="{ item }">
            <span class="wrap-content">
              {{ item.host }}
            </span>
          </template>
          <template v-slot:item.required_data="{ item }">
            <span class="wrap-content">
              {{ item.required_data }}
            </span>
          </template>
          <template v-slot:item.action="{ item }">
            <span
              class="primary--text font-weight-bold"
              @click="handleCheckAction(item.id)"
              >Check</span
            >
          </template>
          <template v-slot:item.status="{ item }">
            <v-icon color="success" v-if="item.status">{{
              icons.check
            }}</v-icon>
            <v-icon color="error" v-else>{{ icons.cancel }}</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Loading :value="loading" />
  </v-form>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import Loading from '@/components/common/Loading'
import { mdiCheck, mdiClose } from '@mdi/js'

export default {
  name: 'EmailDomain',
  mixins: [SnackbarMixin, ErrorHandlerMixin],

  components: {
    Loading,
  },

  data() {
    return {
      loading: false,
      form: new Form({
        domain: '',
      }),
      headers: [
        {
          text: 'Record Type',
          value: 'record_type',
          sortable: false,
          width: '20%',
        },
        {
          text: 'Host',
          value: 'host',
          sortable: false,
          width: '30%',
        },
        {
          text: 'Required Data',
          value: 'required_data',
          sortable: false,
          width: '30%',
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          width: '10%',
          align: 'center',
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
          width: '10%',
        },
      ],
      icons: {
        check: mdiCheck,
        cancel: mdiClose,
      },
    }
  },

  created() {
    this.getDomain()
    this.initForm()
  },

  computed: {
    ...mapState({
      emailDomains: (state) => state.organisation.emailDomains,
      orgDomain: (state) => state.organisation.orgDomain,
    }),

    buttonLabel() {
      return 'Save Changes'
    },
  },

  watch: {
    orgDomain() {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      createEmailDomain: 'organisation/createEmailDomain',
      checkDNSEmail: 'organisation/checkDNSEmail',
      getOrgSetting: 'organisation/getOrgSetting',
    }),

    ...mapMutations({
      clearOrgDomain: 'organisation/clearOrgDomain',
    }),

    initForm() {
      if (this.orgDomain) {
        const content = JSON.parse(this.orgDomain.content)
        this.form.domain = content.domain || ''
      }
    },

    async submit() {
      if (this.form.$busy) return

      this.form.$busy = true
      this.form.$clearErrors()

      await this.createEmailDomain({
        type: 'email_domain',
        content: JSON.stringify({ domain: this.form.domain }),
      })
        .then(() => {
          this.showSnackbar('DNS records successfully generated!')
          this.getDomain()
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
        .finally(() => {
          this.form.$busy = false
          this.loading = false
        })
    },

    async handleCheckAction(id) {
      if (this.loading) return

      this.loading = true
      await this.checkDNSEmail({ id })
        .then((res) => {
          if (res.success) {
            this.showSnackbar('DNS Record have updated!')
          } else {
            this.showSnackbar(res.message, false)
          }
        })
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    async getDomain() {
      this.clearOrgDomain
      await this.getOrgSetting()
    },
  },
}
</script>

<style lang="scss">
.custom-table .wrap-content {
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word; /* Ensure long words break */
  overflow-wrap: anywhere; /* Break text at any point if necessary */
}
</style>
