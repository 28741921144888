<template>
  <v-dialog
    :value="open"
    @input="$emit('input')"
    max-width="700"
    class="groups"
  >
    <v-card>
      <v-card-title class="headline">
        <v-app-bar flat color="transparent" class="main-appbar">
          <v-toolbar-title class="headline font-weight-bold"
            >Groups ({{ totalGroups }})</v-toolbar-title
          >

          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="appbar__btn mx-4"
            height="40px"
            @click="handleCreate"
            v-if="hasCreatePermission"
          >
            <v-icon class="mr-2">{{ icons.add }}</v-icon>
            Create Groups
          </v-btn>
        </v-app-bar>
      </v-card-title>
      <v-card-actions>
        <v-data-table
          class="groups-table"
          :headers="headers"
          :loading="loading"
          :items="groupList"
          :sort-by.sync="defaultFilter"
          :sort-desc.sync="sortOrder"
          :footer-props="{
            'items-per-page-options': [5, 10, 25, -1],
          }"
          :server-items-length="totalGroups"
          width="100%"
          @update:options="fetchGroups"
          :items-per-page.sync="itemsPerPageTables"
          @pagination="handleScrollAfterTableRender"
        >
          <template v-slot:item.users_count="{ item }">
            <div class="primary--text">
              {{ item.users_count }}
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              class="mr-2"
              v-if="hasEditPermission"
              @click="handleEdit(item.id)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="hasDeletePermission"
              @click="
                deleteConfirmDialog = true
                itemSelected = item
              "
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-actions>
    </v-card>

    <div class="group-confirm-modal">
      <ConfirmModal
        v-model="deleteConfirmDialog"
        title="Remove Group"
        @cancel="
          deleteConfirmDialog = false
          itemSelected = null
        "
        @confirm="handleDeleteGroup"
        :message="deleteMessage"
        cancel-text="No"
        confirm-text="Yes"
        max-width="350"
      ></ConfirmModal>
    </div>
  </v-dialog>
</template>

<script>
/**
 * ==================================================================================
 * Modal Groups
 * ==================================================================================
 **/

import { mapState, mapMutations, mapActions } from 'vuex'
import { mdiPlus } from '@mdi/js'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import { sortFilter, perPageFilter } from '@/utils/helpers'

export default {
  components: { ConfirmModal },

  mixins: [SnackbarMixin, ErrorHandlerMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    open(newValue, oldValue) {
      if (newValue) {
        this.fetchInitParams()
      }
    },
  },

  data() {
    return {
      itemSelected: null,
      loading: false,
      deleteConfirmDialog: false,
      icons: {
        add: mdiPlus,
      },
      defaultFilter: 'name',
      sortOrder: false,
      headers: [
        {
          text: 'Group Name',
          value: 'name',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Members',
          value: 'users_count',
          sortable: false,
        },
        {
          text: 'Actions',
          value: 'actions',
          width: '150px',
          align: 'center',
          sortable: false,
        },
      ],
      itemsPerPageTables: 5,
    }
  },

  computed: {
    ...mapState({
      groupList: (state) => state.group.list,
      permissions: (state) => state.auth.permissions,
      totalGroups: (state) => state.group.totalGroups,
      paramsGroup: (state) => state.group.queryParams,
      scrollPosition: (state) => state.group.scrollPosition,
    }),

    header() {
      return 'Add Booking'
    },

    memberCount() {
      return this.itemSelected ? this.itemSelected.users_count : 0
    },

    deleteMessage() {
      return `Warning, you are about to delete a group with  ${
        this.memberCount
      } ${
        this.memberCount > 1 ? 'members' : 'member'
      }. Are you sure you want to continue?`
    },

    hasCreatePermission() {
      return validatePermissions([PERMISSION.GROUP_CREATE], this.permissions)
    },

    hasDeletePermission() {
      return validatePermissions([PERMISSION.GROUP_DELETE], this.permissions)
    },

    hasEditPermission() {
      return validatePermissions([PERMISSION.GROUP_UPDATE], this.permissions)
    },
  },

  created() {
    this.fetchInitParams()
  },

  methods: {
    ...mapMutations({
      clearGroups: 'group/clearGroupList',
      clearGroupDetails: 'group/clearGroupDetails',
      setParams: 'group/setQueryParams',
      setScrollPosition: 'group/setScrollPosition',
    }),

    ...mapActions({
      getGroups: 'group/getGroups',
      getGroupDetails: 'group/getGroupDetails',
      deleteGroup: 'group/deleteGroup',
    }),

    fetchInitParams() {
      if (this.paramsGroup?.itemsPerPage) {
        const { itemsPerPage } = this.paramsGroup
        this.itemsPerPageTables = [5, 10, 25].includes(itemsPerPage)
          ? itemsPerPage
          : -1
      }

      if (this.paramsGroup?.sort) {
        if (this.paramsGroup.sort[0].includes('-')) {
          this.defaultFilter = this.paramsGroup.sort.slice(1)
          this.sortOrder = true
        } else {
          this.defaultFilter = this.paramsGroup?.sort
          this.sortOrder = false
        }
      } else {
        this.defaultFilter = 'name'
      }

      this.fetchGroups(null, 1, this.paramsGroup)
    },

    async fetchGroups(options, page = 1) {
      if (this.loading || !this.open) return
      let params = {
        page: options?.page || 1,
        itemsPerPage: perPageFilter(
          options,
          this.totalGroups,
          this.itemsPerPageTables
        ),
        sort: sortFilter(options, this.defaultFilter, this.sortOrder, 'name'),
        paginate: true,
      }

      this.clearGroups()
      this.loading = true

      await this.getGroups(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
          this.scrollToPosition()
          this.setParams(params)
        })
    },

    handleEdit(id) {
      if (!this.hasEditPermission) return
      this.getGroup(id)
      this.$emit('show')
      this.setScrollPosition(window.scrollY)
    },

    handleCreate() {
      this.clearGroupDetails()
      this.$emit('show')
    },

    async getGroup(id) {
      if (!id) return false

      this.loading = true
      await this.getGroupDetails(id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    async handleDeleteGroup() {
      if (this.loading || !this.itemSelected) return
      this.loading = true

      await this.deleteGroup({
        id: this.itemSelected.id,
      })
        .then(() => {
          this.showSnackbar(`Group deleted successfully!`)
          this.deleteConfirmDialog = false
          this.itemSelected = null
        })
        .catch((error) => {
          this.showSnackbar(this.getErrorMessage(error), false)
        })
        .finally(() => {
          this.loading = false
          this.fetchGroups()
        })
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return
      this.scrollToPosition()
    },

    scrollToPosition() {
      const scrollPosition = this.scrollPosition

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>

<style lang="scss">
.groups-table {
  width: 100% !important;
}

.group-confirm-modal {
  .v-dialog {
    max-width: 350px !important;
  }
}
</style>
