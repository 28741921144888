<template>
  <div class="supplier-details py-8">
    <SupplierAppbar />

    <v-row class="mx-4">
      <v-col cols="12" class="ma-0 pa-0">
        <v-row>
          <v-col cols="6" md="5">
            <ViewingTitle
              :title="displayName"
              class="supplier-details__title"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="ma-0 pa-0">
        <v-col cols="6" md="5" class="text-end">
          <SupplierArchive
            v-if="hasDeletePermissions"
            :model="supplier"
            name="Supplier"
          />
        </v-col>
      </v-col>
      <v-col cols="12" class="ma-0 pa-0">
        <div class="d-flex mb-6">
          <v-tabs hide-slider class="asset-info-tabs">
            <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
              {{ item.tab }}
            </v-tab>

            <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
              <v-divider class="supplier-details__divider"></v-divider>

              <SupplierForm
                class="supplier-details__form"
                :supplier="supplier"
                :is-update="true"
              />
            </v-tab-item>
          </v-tabs>
        </div>
      </v-col>
    </v-row>

    <Loading :value="loading" />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * View Supplier Request
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import SupplierAppbar from '@/views/Home/Maintenance/Suppliers/components/SupplierAppbar'
import SupplierForm from '@/views/Home/Maintenance/Suppliers/components/SupplierForm'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import SupplierArchive from '@/views/Home/Maintenance/Suppliers/components/SupplierArchive'
import ViewingTitle from '@/components/common/ViewingTitle'

export default {
  name: 'SupplierDetails',

  components: {
    ViewingTitle,
    SupplierArchive,
    SupplierAppbar,
    SupplierForm,
    Loading,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      supplier: (state) => state.supplier.supplierDetails,
      permissions: (state) => state.auth.permissions,
    }),

    displayName() {
      return this.supplier ? this.supplier.company_name : null
    },

    hasDeletePermissions() {
      return validatePermissions(
        [PERMISSION.SUPPLIERS_DELETE],
        this.permissions
      )
    },
  },

  methods: {
    ...mapActions({
      getSupplierDetails: 'supplier/getSupplierDetails',
    }),

    ...mapMutations({
      clearSupplierDetails: 'supplier/clearSupplierDetails',
    }),

    async getSupplier() {
      this.loading = true
      await this.getSupplierDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getSupplier()
  },

  destroyed() {
    this.clearSupplierDetails()
  },

  watch: {
    $route() {
      this.getSupplier()
    },
  },
}
</script>

<style lang="scss" scoped>
.supplier-details {
  &__title {
    padding-right: 10px;
  }

  &__divider {
    max-width: 400px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
