<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-invoice-fast</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Invoices ({{ totalInvoices }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <div class="invoice-details__status_action">
        <div class="invoice-details__action">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :style="{ backgroundColor: '#606060' }"
                height="40px"
                dark
                v-bind="attrs"
                :loading="loadingAction"
                v-on="on"
              >
                <v-icon class="mr-2">{{ icons.chevronDown }}</v-icon>
                Bulk Actions
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in actionsWithPermissions"
                :key="index"
              >
                <v-list-item-title
                  @click="item.permission && actionHandel(item.value)"
                  :style="{
                    cursor: item.permission ? 'pointer' : 'not-allowed',
                    color: item.permission ? 'black' : 'gray',
                  }"
                  >{{ item.text }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-btn
          class="appbar__btn ml-5 font-weight-bold"
          height="40px"
          @click="exportList"
        >
          <v-icon class="mr-2">mdi-export-variant</v-icon>
          EXPORT
        </v-btn>
      </div>
    </v-app-bar>

    <div
      class="mx-4"
      id="invoice-list"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-tabs v-model="defaultStatus">
          <v-tab
            class="pa-3"
            v-for="item in tabItems"
            :key="item.index"
            :ref="item.index"
            :disabled="defaultStatus !== item.index && loading"
            @click="handleTabClick(item.index)"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>

        <v-spacer />
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search"
          @input="search"
        ></v-text-field>
      </div>
      <v-data-table
        :headers="headers"
        :items="invoices"
        v-model="selected"
        :loading="loading"
        show-select
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        :server-items-length="totalInvoices"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        :items-per-page.sync="itemsPerPageTables"
        @click:row="editItem"
        @update:options="fetch"
        @toggle-select-all="selectAllToggle"
        @pagination="handleScrollAfterTableRender"
      >
        <template v-slot:item.data-table-select="{ item, isSelected, select }">
          <v-simple-checkbox
            :value="isSelected"
            :readonly="isRowDisabled(item)"
            :disabled="isRowDisabled(item)"
            @input="select($event)"
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.is_active="{ item }">
          <v-checkbox
            v-model="item.is_active"
            disabled
            readonly
            class="mt-0 mb-0"
          />
        </template>
        <template v-slot:item.maintenance_id="{ item }">
          <div v-if="item.maintenance_id">
            <router-link
              class="text-decoration--none"
              :to="{
                name: 'maintenance.request.details',
                params: { id: item.maintenance_id },
              }"
            >
              {{ item.maintenance_id }}</router-link
            >
          </div>
        </template>
        <template v-slot:item.status="{ item }">
          <div style="text-align: center">
            <Status :status="item.statusLabel" />
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div v-if="item.addedDate">{{ item.addedDate }}</div>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <div v-if="item.updatedDate">{{ item.updatedDate }}</div>
        </template>
        <template v-slot:item.amount="{ item }">
          <div v-if="item.amountFormat">{{ item.amountFormat }}</div>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="dialogSendToEmail" max-width="600px">
      <v-form ref="formInvoice" class="faq-form__form">
        <v-card>
          <v-card-title>
            <span class="text-h5"
              >There are {{ countInvoiceUnapproved }} invoices that have not yet
              been approved. Do you want to approve these invoices and send?
            </span>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialogSendToEmail = false"
            >
              No
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="confirmSendToEmail()"
              name="submitFormInvoice"
              :loading="loadingAction"
            >
              Approve and send
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogMarkAsPaid" max-width="600px">
      <v-form ref="formInvoice" class="faq-form__form">
        <v-card>
          <v-card-title>
            <span class="text-h5"
              >There are {{ countInvoiceUnsent }} invoices that have not yet
              been sent. Do you want to mark as paid these invoices?
            </span>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogMarkAsPaid = false">
              No
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="confirmMarkAsPaid()"
              name="submitFormInvoice"
              :loading="loadingAction"
            >
              Mark as Paid
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <ConfirmModal
      v-model="showBulkDeleteModal"
      title=""
      :message="messageDelete"
      @cancel="showBulkDeleteModal = false"
      @confirm="
        bulkHandle(bulkDeleteInvoice)
        showBulkDeleteModal = false
      "
      confirmText="Yes"
      cancelText="No"
    ></ConfirmModal>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Invoice list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiPlus, mdiChevronDown } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import Status from '@/components/common/Status.vue'
import ACTIONS from '@/utils/enums/InvoiceActions'
import {
  sortFilter,
  perPageFilter,
  messageConfirmDelete,
} from '@/utils/helpers'
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'InvoicesPage',
  mixins: [FilterTableMixin, ControlsMixin, SnackbarMixin, ErrorHandlerMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    Status,
    AppBarNavIcon,
    ConfirmModal,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        chevronDown: mdiChevronDown,
        add: mdiPlus,
      },
      tabItems: [
        { tab: 'Pending', value: 'new', index: 0 },
        { tab: 'Approved', value: 'approved', index: 1 },
        { tab: 'Deleted', value: 'deleted', index: 2 },
        { tab: 'All', value: 'null', index: 3 },
      ],
      defaultFilter: 'invoice_number',
      sortOrder: true,
      headers: [
        {
          text: 'Supplier Name',
          value: 'supplierName',
          sortable: false,
        },
        {
          text: 'Invoice #',
          value: 'invoice_number',
        },
        {
          text: 'Property',
          value: 'propertyName',
          sortable: false,
        },
        {
          text: 'Building',
          value: 'buildingName',
          sortable: false,
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Request',
          value: 'maintenance_id',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Updated Date',
          value: 'updated_at',
        },
        {
          text: 'Added date',
          value: 'created_at',
        },
        {
          text: 'Amount',
          value: 'amount',
        },
      ],
      selected: [],
      defaultStatus: 0,
      loadingAction: false,
      dialogSendToEmail: false,
      dialogMarkAsPaid: false,
      actions: ACTIONS,
      itemsPerPageTables: 25,
      exportParams: null,
      showBulkDeleteModal: false,
    }
  },

  created() {
    this.fetchInitParams()
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      invoices: (state) => state.invoice.list,
      listMeta: (state) => state.invoice.listMeta,
      permissions: (state) => state.auth.permissions,
      totalInvoices: (state) => state.invoice.totalInvoices,
      paramsInvoice: (state) => state.invoice.queryParams,
      scrollPosition: (state) => state.invoice.scrollPosition,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },

    hasCreatePermission() {
      return validatePermissions([PERMISSION.INVOICES_CREATE], this.permissions)
    },

    countInvoiceUnapproved() {
      let count = 0
      this.selected.forEach((invoice) => {
        if (invoice.status !== 'approved') count = count + 1
      })

      return count
    },

    countInvoiceUnsent() {
      let count = 0
      this.selected.forEach((invoice) => {
        if (invoice.status !== 'sent') count = count + 1
      })

      return count
    },

    messageDelete() {
      return messageConfirmDelete(this.selected.length)
    },

    actionsWithPermissions() {
      return this.actions.map((action) => ({
        ...action,
        permission: this.bulkPermission(action.value),
      }))
    },
  },

  methods: {
    ...mapActions({
      getInvoices: 'invoice/getInvoices',
      bulkDeleteInvoice: 'invoice/bulkDeleteInvoice',
      bulkApproveInvoice: 'invoice/bulkApproveInvoice',
      bulkMarkAsPaid: 'invoice/bulkMarkAsPaid',
      bulkSendToEmail: 'invoice/bulkSendToEmail',
      exportToExcel: 'invoice/exportToExcel',
    }),

    ...mapMutations({
      clearInvoices: 'invoice/clearInvoiceList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'invoice/setQueryParams',
      setScrollPosition: 'invoice/setScrollPosition',
    }),

    filterList(filterValue) {
      this.fetch(filterValue)
    },

    fetchInitParams() {
      if (this.paramsInvoice?.filter) {
        this.defaultStatus = this.paramsInvoice.filter
      }

      if (this.paramsInvoice?.itemsPerPage) {
        const { itemsPerPage } = this.paramsInvoice
        this.itemsPerPageTables = [25, 50, 100].includes(itemsPerPage)
          ? itemsPerPage
          : -1
      }

      if (this.paramsInvoice?.sort) {
        if (this.paramsInvoice.sort[0].includes('-')) {
          this.defaultFilter = this.paramsInvoice.sort.slice(1)
          this.sortOrder = true
        } else {
          this.defaultFilter = this.paramsInvoice?.sort
          this.sortOrder = false
        }
      } else {
        this.defaultFilter = 'invoice_number'
      }

      if (this.paramsInvoice?.search) {
        this.filter.search = this.paramsInvoice.search
      }

      this.fetch(null, 1, this.paramsInvoice)
    },

    handleTabClick(status) {
      this.defaultStatus = status
      this.defaultFilter = 'invoice_number'
      this.itemsPerPageTables = 25
      this.selected = []
      this.fetch()
    },

    async bulkHandle(bulkApi) {
      if (this.selected.length === 0) {
        this.showSnackbar('Please select invoices', false)
        this.setCurrentAction(null)
        return
      }

      this.loadingAction = true
      const formData = new FormData()
      this.selected.forEach((invoice) => {
        formData.append('invoice_ids[]', invoice.id)
      })

      await bulkApi(formData)
        .then(() => {
          this.loadingAction = false
          this.selected = []
          this.showSnackbar('Bulk successfully updated!')
          this.fetch()
        })
        .catch((err) => {
          this.loadingAction = false
          this.selected = []
          this.showSnackbar(this.getValidationErrors(err), false)
        })
    },

    async exportList() {
      this.setLoadingBuilding(true)
      this.loading = true

      await this.exportToExcel(this.exportParams)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'invoices_list.xlsx')
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('Invoice successfully downloaded')
          this.setLoadingBuilding(false)
        })
        .catch((err) => {
          this.showSnackbar(
            this.getErrorMessage(err, 'Oops! Something went wrong'),
            false
          )
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },

    search: debounce(function () {
      this.fetch()
    }, 600),

    async fetch(options, page = 1, paramsInvoice = null) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: perPageFilter(
          options,
          this.totalInvoices,
          this.itemsPerPageTables
        ),
        sort: sortFilter(
          options,
          this.defaultFilter,
          this.sortOrder,
          'invoice_number'
        ),
        filter: this.defaultStatus,
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.clearInvoices()
      this.loading = true
      await this.getInvoices(paramsInvoice || params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
          this.setParams(params)
          this.exportParams = paramsInvoice || params
          this.scrollToPosition()
        })
    },

    editItem(item) {
      this.$router.push({
        name: 'maintenance.invoice.details',
        params: { id: item.id },
      })
    },

    async confirmSendToEmail() {
      if (this.selected.length === 0) {
        this.showSnackbar('Please select invoices', false)
        this.setCurrentAction(null)
        return
      }

      this.loadingAction = true
      const formData = new FormData()
      this.selected.forEach((invoice) => {
        formData.append('invoice_ids[]', invoice.id)
      })
      await this.bulkSendToEmail(formData).then(() => {
        this.showSnackbar('Send to Email successfully!')
        this.dialogSendToEmail = false
        this.loadingAction = false
        this.selected = []
        this.fetch()
      })
    },

    async confirmMarkAsPaid() {
      if (this.selected.length === 0) {
        this.showSnackbar('Please select invoices', false)
        this.setCurrentAction(null)
        return
      }

      this.loadingAction = true
      const formData = new FormData()
      this.selected.forEach((invoice) => {
        formData.append('invoice_ids[]', invoice.id)
      })
      await this.bulkMarkAsPaid(formData).then(() => {
        this.showSnackbar('Mark as Paid Invoices successfully!')
        this.dialogMarkAsPaid = false
        this.loadingAction = false
        this.fetch()
      })
    },

    isRowDisabled(item) {
      return item.deleted_at !== null
    },

    selectAllToggle(props) {
      const selectableItems = props.items.filter((item) => !item.deleted_at)
      if (selectableItems.length !== this.selected.length) {
        this.selected = []
        const self = this
        props.items.forEach((item) => {
          if (!item.deleted_at) {
            self.selected.push(item)
          }
        })
      } else {
        this.selected = []
      }
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return
      this.scrollToPosition()
    },

    scrollToPosition() {
      const scrollPosition = this.scrollPosition

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },

    actionHandel(action) {
      switch (action) {
        case 'delete':
          if (this.selected.length === 0) {
            this.showSnackbar('Please select invoices', false)
            this.setCurrentAction(null)
            break
          }
          this.showBulkDeleteModal = true
          break
        case 'approve':
          this.bulkHandle(this.bulkApproveInvoice)
          break
        case 'send_to_email':
          if (this.countInvoiceUnapproved > 0) {
            this.dialogSendToEmail = true
          } else {
            this.confirmSendToEmail()
          }
          break
        case 'mark_as_paid':
          if (this.countInvoiceUnsent > 0) {
            this.dialogMarkAsPaid = true
          } else {
            this.confirmMarkAsPaid()
          }
          break
        default:
          console.log('Unknown action type')
      }
    },

    bulkPermission(value) {
      let allowAction = false
      switch (value) {
        case 'delete':
          allowAction = validatePermissions(
            [PERMISSION.INVOICES_DELETE],
            this.permissions
          )
          break
        case 'approve':
        case 'send_to_email':
        case 'mark_as_paid':
        case 'send_to_accounting':
          allowAction = validatePermissions(
            [PERMISSION.INVOICES_UPDATE],
            this.permissions
          )
          break
        default:
          break
      }
      return allowAction
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input.v-input--checkbox {
  justify-content: center;
  margin: 0;

  .v-input__control {
    width: auto;
    flex-grow: 0;
  }

  .v-input__slot {
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}
.invoice-details__status_action {
  display: flex;
  justify-content: flex-end;
  justify-items: center;
  gap: 20px;
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}
.v-list-item__title {
  cursor: pointer;
}
</style>
