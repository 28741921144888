import Gallery from '@/models/Gallery'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    galleryDetails: null,
    totalGalleries: 0,
    queryParams: null,
    scrollPosition: 0,
  },

  mutations: {
    setGalleryList(state, gallery) {
      state.list = gallery
    },

    clearGalleryList(state) {
      state.list = []
      state.totalGalleries = 0
    },

    setGalleryListMeta(state, meta) {
      state.listMeta = meta
    },

    setGalleryDetails(state, gallery) {
      if (state.galleryDetails) {
        if (!gallery.images) gallery.images = state.galleryDetails.images
      }

      if (gallery instanceof Gallery) {
        state.galleryDetails = gallery
      } else {
        state.galleryDetails = new Gallery(gallery)
      }
    },

    setGalleryImage(state, payload) {
      if (state.galleryDetails) {
        state.galleryDetails[payload.image_name] = [
          ...state.galleryDetails.images,
          ...payload.images,
        ]
      }
    },

    clearGalleryDetails(state) {
      state.galleryDetails = null
    },

    setGalleryTotals(state, total) {
      state.totalGalleries = total
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getGallery({ commit, rootGetters }, params) {
      let res = null

      if (params) {
        const query = Gallery.page(params.page || 1)
          .limit(params.itemsPerPage || 10)
          .include(['user', 'building'])

        query.params({ paginate: true })

        if (params.sort) {
          query.orderBy(params.sort)
        }
        if (params.search) {
          query.where('search', encodeURIComponent(params.search))
        }

        switch (params.filter) {
          case 'active':
            query.where('is_active', 1)
            break
          case 'inactive':
            query.where('is_active', 0)
            break
          case 'deleted':
            query.where('trashed', 'only')
            break
          default:
            break
        }

        let filteredBuilding = rootGetters['filter/buildingId']
        if (filteredBuilding) {
          query.where('building_id', filteredBuilding)
        }

        res = await query.get()
        commit('setGalleryList', res.data)
        commit('setGalleryListMeta', res.meta)
        if (get(res, 'meta.total', 0) > 0) {
          commit('setGalleryTotals', get(res, 'meta.total', 0))
        }
      } else {
        res = await Gallery.params({ paginate: false }).get()
        return res.data
      }
    },

    getGalleryByIndex({ state }, index) {
      return state.list && state.list.length > index ? state.list[index] : null
    },

    async getGalleryDetails({ commit }, id) {
      const { data } = await Api.get(`gallery/${id}`)
      commit('setGalleryDetails', data.data)
    },

    async createGallery({ commit }, formData) {
      const { data } = await Api.post(`gallery`, formData)

      const gallery = data.data
      commit('setGalleryDetails', gallery)
    },

    async updateGallery({ commit }, formData) {
      const { data } = await Api.put(`gallery/${formData.id}`, formData)

      const gallery = data.data
      commit('setGalleryDetails', gallery)
    },

    async uploadGalleryImage({ commit }, formData) {
      const { data } = await Api.post(
        `gallery/${formData.get('id')}/images`,
        formData
      )

      commit('setGalleryImage', {
        images: data.data,
        image_name: formData.get('image_name'),
      })
    },

    async deleteGalleryImage({ commit }, formData) {
      await Api.delete(`media/${formData.id}`, formData)
    },

    async bulkActionGallery({ commit }, formData) {
      await Api.post(`bulk-action/gallery`, formData)
    },
  },
}
