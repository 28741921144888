<template>
  <div class="create-parcel-type">
    <ParcelLocationAppbar />
    <div class="ma-4">
      <ParcelLocationForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Parcel Location
 * ==================================================================================
 **/

import ParcelLocationAppbar from '@/views/Home/Parcel/ParcelLocations/components/ParcelLocationAppbar'
import ParcelLocationForm from '@/views/Home/Parcel/ParcelLocations/components/ParcelLocationForm'

export default {
  components: {
    ParcelLocationAppbar,
    ParcelLocationForm,
  },
}
</script>
<style lang="scss"></style>
