<template>
  <v-autocomplete
    class="select-multi-group"
    :chips="value.length > 0"
    required
    v-bind="$attrs"
    :items="validItems"
    :item-value="valueColumn"
    :placeholder="placeholder"
    :item-text="textColumn"
    :value="value"
    :loading="loading || fetching"
    :disabled="disabled || fetching"
    :deletable-chips="deletableChips"
    @input="onChange"
    :item-disabled="disableOption"
    multiple
  >
    <template v-if="propertyId" #selection="{ item }">
      <v-chip
        class="ma-2"
        :close="!hasAssociateProperty(item)"
        @click:close="removeItem(item)"
      >
        <v-icon left v-if="hasAssociateProperty(item)">mdi-home-variant</v-icon>
        {{ item.name }}
      </v-chip>
    </template>
    <template v-else #selection="{ item }">
      <v-chip class="ma-2" close @click:close="removeItem(item)">
        {{ item.name }}
      </v-chip>
    </template>
    <template
      v-if="isHasSelectAll && validItems.length > 1"
      v-slot:prepend-item
    >
      <v-list-item @click="toggleSelectAll">
        <v-list-item-action>
          <v-checkbox :input-value="isSelectAll" color="primary"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Select All</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </template>
  </v-autocomplete>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'
import { arraysEqual } from '@/utils/helpers'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },

    items: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },

    placeholder: {
      type: String,
      default: 'Select building',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    valueColumn: {
      type: String,
      default: 'id',
    },

    textColumn: {
      type: String,
      default: 'name',
    },

    preSelect: {
      type: Boolean,
      default: false,
    },

    preFetch: {
      type: Boolean,
      default: true,
    },

    buildingId: {
      type: [String, Number],
      default: null,
    },

    deletableChips: {
      type: Boolean,
      default: true,
    },
    isHasSelectAll: {
      type: Boolean,
      default: true,
    },
    propertyId: {
      type: [String, Number],
      default: null,
    },

    initUserGroup: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },
  },

  data() {
    return {
      fetching: false,
      groups: [],
      isSelectAll: false,
    }
  },

  watch: {
    value(newValue) {
      this.isSelectAll =
        arraysEqual(
          this.validItems.map((item) => item.id),
          newValue
        ) && newValue.length > 0
    },
    buildingId(newValue, oldValue) {
      this.fetch()
    },

    propertyId(newValue) {
      this.handleSelectedByProperty()
    },

    validItems() {
      if (this.propertyId) {
        this.handleSelectedByProperty()
      }
    },
  },

  computed: {
    validItems() {
      let items = [
        ...this.items,
        ...(this.groups && this.groups.length ? this.groups : []),
      ]

      items = items.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      )

      return !this.viaIndex
        ? items
        : items.map((item, i) => {
            return {
              index: i,
              ...item,
            }
          })
    },

    viaIndex() {
      return this.valueColumn.toLowerCase() === 'index'
    },
  },

  created() {
    if (this.preFetch) {
      this.fetch()
    }
  },

  methods: {
    ...mapActions({
      getGroups: 'group/getGroups',
    }),

    ...mapMutations({
      clearGroups: 'group/clearGroupList',
    }),

    hasAssociateProperty(group) {
      return (
        this.propertyId &&
        group.properties &&
        group.properties.map((pro) => pro.id).includes(this.propertyId)
      )
    },

    disableOption(item) {
      return this.hasAssociateProperty(item) && this.value.includes(item.id)
    },

    async fetch() {
      this.fetching = true
      this.clearGroups()

      if (this.buildingId) {
        await this.getGroups({ building_id: this.buildingId })
          .then((data) => {
            this.groups = data
            this.$emit('populated', this.groups)
          })
          .finally(() => {
            this.fetching = false
          })
      } else {
        this.fetching = false
      }
    },

    onChange(value) {
      this.$emit('input', value)
    },

    toggleSelectAll() {
      let value = []
      if (!this.isSelectAll) {
        value = this.validItems.map((item) => item[this.valueColumn])
      } else if (this.propertyId) {
        value = this.validItems.filter((item) =>
          this.hasAssociateProperty(item)
        )
      }

      this.$emit('input', value)
    },

    removeItem(group) {
      let value = []
      value = this.value.filter((item) => item !== group.id)
      this.$emit('input', value)
    },

    handleSelectedByProperty() {
      if (this.propertyId) {
        let value = []
        value = this.validItems
          .filter(
            (item) =>
              this.hasAssociateProperty(item) ||
              this.initUserGroup.includes(item.id)
          )
          .map((item) => item[this.valueColumn])

        this.$emit('input', value)
      }
    },
  },
}
</script>
