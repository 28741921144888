<template>
  <tr :class="getClass" @click="$emit('click')">
    <td v-if="showSelect">
      <div style="text-align: center">
        <v-simple-checkbox
          :value="selected"
          @input="$emit('toggle-selected', item)"
        />
      </div>
    </td>

    <td v-for="(header, index) in headers" :key="index">
      <slot :item="item" :name="columnName(header)">
        <div :style="getAlignment(header)">
          {{ getNonSlotValue(item, header) }}
        </div>
      </slot>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'DataTableRowHandler',
  components: {},
  props: {
    itemClass: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
    headers: {
      type: Array,
      default: () => {
        return []
      },
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    getClass() {
      return this.itemClass
    },
  },
  methods: {
    columnName(header) {
      return `item.${header.value}`
    },
    getAlignment(header) {
      const align = header.align ? header.align : 'left'
      return `text-align: ${align}`
    },
    getNonSlotValue(item, header) {
      const val = item[header.value]

      if (val) {
        return val
      }

      return ''
    },
  },
}
</script>
