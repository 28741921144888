<template>
  <Navbar name="Parcel Locations" redirect="parcel.parcel-locations" />
</template>
<script>
/**
 * ==================================================================================
 * Parcel Location Appbar
 * ==================================================================================
 **/

import Navbar from '@/components/Navbar'

export default {
  components: {
    Navbar,
  },
}
</script>
