function truncate(str, n = 75) {
  return str.length > n ? str.substr(0, n - 1) + '..' : str
}

function capitalize(str) {
  return str ? str.toString().charAt(0).toUpperCase() + str.slice(1) : ''
}

function sentence(arr) {
  if (Array.isArray(arr)) {
    if (arr.length <= 2) {
      return arr.join(' and ')
    }

    const last = arr.pop()
    return arr.join(', ') + ' and ' + last
  }

  return arr
}

function isValidLink(link) {
  /* eslint-disable-next-line no-useless-escape */
  return /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(link)
}

function isValidEmail(email) {
  const reg =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  return email ? reg.test(String(email).toLowerCase()) : false
}

function arraysEqual(values, itemIds) {
  if (values.length !== itemIds.length) return false

  const sortValues = values.slice().sort()
  const sortItems = itemIds.slice().sort()

  return sortValues.every((value, index) => value === sortItems[index])
}

function sortFilter(options, filter, sortOrder = false, sortDefault = '') {
  return options?.sortBy[0]
    ? options.sortDesc[0]
      ? '-' + options.sortBy[0]
      : options.sortBy[0]
    : filter
    ? sortOrder
      ? '-' + filter
      : filter
    : sortDefault
}

function perPageFilter(options, total, itemsPerPage) {
  return options?.itemsPerPage
    ? options?.itemsPerPage === -1
      ? total
      : options.itemsPerPage
    : itemsPerPage === -1
    ? total
    : itemsPerPage
}

function messageConfirmDelete(items) {
  return `You are about to delete ${items} ${
    items > 1 ? 'items' : 'item'
  }, are you sure you want to proceed?`
}

export {
  capitalize,
  truncate,
  sentence,
  isValidEmail,
  isValidLink,
  arraysEqual,
  sortFilter,
  perPageFilter,
  messageConfirmDelete,
}
