<template>
  <div class="parcel-details py-8">
    <ParcelAppbar />

    <div class="ma-4">
      <v-row>
        <v-col cols="12" md="6">
          <ViewingTitle :title="displayName" class="parcel-details__title" />
        </v-col>
        <v-col cols="12" md="6">
          <div v-if="hasUpdatePermission" class="w-100 d-flex justify-end">
            <v-btn
              class="mr-5"
              color="primary"
              dark
              :loading="loading"
              height="40px"
              @click="actionHandle('collected')"
            >
              Collected
            </v-btn>
            <BulkAction
              :sub-items="actions"
              show-icon
              :color-primary="false"
              text="Actions"
              @selected="actionHandle"
            />
          </div>
        </v-col>
      </v-row>

      <DeleteOrRestore
        v-if="hasDeletePermission"
        :model="parcel"
        name="parcels"
        redirect="/parcel/dashboard"
      />

      <div class="d-flex mb-6">
        <v-tabs hide-slider class="parcel-info-tabs">
          <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
            {{ item.tab }}
          </v-tab>

          <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
            <v-divider class="parcel-details__divider"></v-divider>

            <ParcelForm class="parcel-details__form" :parcel="parcel" />
            <!-- :access-building="accessBuilding" -->
          </v-tab-item>
        </v-tabs>
      </div>
    </div>

    <Loading :value="loading" />
  </div>
</template>

<script>
/**
 * ==================================================================================
 * View Parcel
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import ParcelForm from '@/views/Home/Parcel/Parcels/components/ParcelUpdateForm'
import ParcelAppbar from '@/views/Home/Parcel/Parcels/components/ParcelAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import BulkAction from '@/components/common/BulkAction'
import { ACTIONS, ACTION_MESSAGES } from '@/utils/enums/ParcelAction'
import { access } from '@/utils/accessBuilding'

export default {
  name: 'ParcelDetails',

  components: {
    ViewingTitle,
    ParcelAppbar,
    ParcelForm,
    Loading,
    DeleteOrRestore,
    BulkAction,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
      actions: ACTIONS,
    }
  },

  computed: {
    ...mapState({
      parcel: (state) => state.parcel.parcelDetails,
      permissions: (state) => state.auth.permissions,
      buildingList: (state) => state.filter.buildingList,
    }),

    displayName() {
      return this.parcel ? this.parcel.parcel_id : null
    },

    hasDeletePermission() {
      return (
        validatePermissions([PERMISSION.PARCEL_DELETE], this.permissions) &&
        this.accessBuilding
      )
    },

    hasUpdatePermission() {
      return validatePermissions([PERMISSION.PARCEL_UPDATE], this.permissions)
    },

    accessBuilding() {
      return access(this.parcel, this.buildingList)
    },
  },

  methods: {
    ...mapActions({
      getParcelDetails: 'parcel/getParcelDetails',
      collectedActionParcel: 'parcel/collectedActionParcel',
      sendActionParcel: 'parcel/sendActionParcel',
    }),

    ...mapMutations({
      clearParcelDetails: 'parcel/clearParcelDetails',
    }),

    async getParcel() {
      this.loading = true
      await this.getParcelDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    async actionHandle(action) {
      this.loading = true
      await this.sendActionParcel({ id: this.parcel.id, action: action })
        .then(() => {
          this.showSnackbar(this.getMessageAction(action))
          this.getParcel()
        })
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    getMessageAction(action) {
      return ACTION_MESSAGES[action] || action
    },
  },

  created() {
    this.getParcel()
  },

  destroyed() {
    this.clearParcelDetails()
  },

  watch: {
    $route() {
      this.getParcel()
    },
  },
}
</script>

<style lang="scss" scoped>
.parcel-details {
  &__divider {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
