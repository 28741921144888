<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-store</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Services & Spaces ({{ totalBookables }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        v-if="hasCreatePermission"
        color="primary"
        class="appbar__btn"
        height="40px"
        :to="{ name: 'bookable.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Create Service/Space
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="bookable-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-select
          hide-details
          dense
          item-value="value"
          class="appbar__option"
          v-model="defaultOption"
          :disabled="loading"
          :items="optionFilter"
          @change="activeAction"
        />
        <v-spacer />
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search type, building or title"
          @input="search"
        ></v-text-field>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-10 bulk-btn"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              :loading="loadingAction"
              height="40px"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in actionsWithPermissions"
              :key="index"
            >
              <v-list-item-title
                @click="item.permission && actionHandel(item.value)"
                :style="{
                  cursor: item.permission ? 'pointer' : 'not-allowed',
                  color: item.permission ? 'black' : 'gray',
                }"
                >{{ item.label }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-data-table
        :headers="headers"
        :items="bookables"
        v-model="selected"
        show-select
        :loading="loading"
        :server-items-length="totalBookables"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        @click:row="editItem"
        @update:options="fetch"
        :items-per-page.sync="itemsPerPageTables"
        @pagination="handleScrollAfterTableRender"
      >
        <template v-slot:item.is_featured="{ item }">
          <v-checkbox
            v-model="item.is_featured"
            disabled
            readonly
            class="mt-0 mb-0"
          />
        </template>
        <template v-slot:item.is_active="{ item }">
          <v-checkbox
            v-model="item.is_active"
            disabled
            readonly
            class="mt-0 mb-0"
          />
        </template>
        <template v-slot:item.building="{ item }">
          {{ item.buildingName }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.addedDate }}
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(bookables.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
    <ConfirmModal
      v-model="showBulkDeleteModal"
      title=""
      :message="messageDelete"
      @cancel="showBulkDeleteModal = false"
      @confirm="
        bulkActions('delete')
        showBulkDeleteModal = false
      "
      confirmText="Yes"
      cancelText="No"
    ></ConfirmModal>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Bookable list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { BOOKING_OPTION } from '@/utils/enums/BookingOption'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { messageConfirmDelete } from '@/utils/helpers'

export default {
  name: 'BookablesPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
    ConfirmModal,
  },

  data() {
    return {
      loading: false,
      loadingAction: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultOption: null,
      defaultFilter: 'title',
      sortOrder: false,
      headers: [
        {
          text: 'Title',
          value: 'title',
          width: '17%',
        },

        {
          text: 'Tag Line',
          value: 'tagline',
          width: '17%',
        },
        {
          text: 'Type',
          value: 'type',
          width: '10%',
        },
        {
          text: 'Building',
          value: 'building',
          width: '10%',
        },

        {
          text: 'Booking Option',
          value: 'booking_option',
          align: 'center',
          width: '10%',
        },
        {
          text: 'Featured',
          value: 'is_featured',
          align: 'center',
          width: '7%',
        },
        {
          text: 'Active',
          value: 'is_active',
          align: 'center',
          width: '7%',
        },
        {
          text: 'Added date',
          value: 'created_at',
          width: '14%',
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
          permission: false,
        },
      ],
      itemsPerPageTables: 25,
      showBulkDeleteModal: false,
      selected: [],
    }
  },

  created() {
    this.clearBookables()
    this.fetchInitParams()
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      bookables: (state) => state.bookable.list,
      listMeta: (state) => state.bookable.listMeta,
      permissions: (state) => state.auth.permissions,
      totalBookables: (state) => state.bookable.totalBookables,
      paramsBookable: (state) => state.bookable.queryParams,
      scrollPosition: (state) => state.bookable.scrollPosition,
    }),

    optionFilter() {
      return [
        {
          text: 'All',
          value: null,
        },
        ...BOOKING_OPTION,
      ]
    },

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },

    hasCreatePermission() {
      return validatePermissions(
        [PERMISSION.BOOKABLES_CREATE],
        this.permissions
      )
    },

    messageDelete() {
      return messageConfirmDelete(this.selected.length)
    },

    actionsWithPermissions() {
      return this.actions.map((action) => ({
        ...action,
        permission: this.bulkPermission(action.value),
      }))
    },
  },

  methods: {
    ...mapActions({
      getBookables: 'bookable/getBookables',
      bulkActionBookable: 'bookable/bulkActionBookable',
    }),

    ...mapMutations({
      clearBookables: 'bookable/clearBookableList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'bookable/setQueryParams',
      setScrollPosition: 'bookable/setScrollPosition',
      setListBookParams: 'bookable/setListBookParams',
    }),

    fetchInitParams() {
      if (this.paramsBookable?.filter) {
        this.defaultOption = this.paramsBookable.filter
      }

      if (this.paramsBookable?.itemsPerPage) {
        const { itemsPerPage } = this.paramsBookable
        this.itemsPerPageTables = [25, 50, 100].includes(itemsPerPage)
          ? itemsPerPage
          : -1
      }

      if (this.paramsBookable?.sort) {
        if (this.paramsBookable.sort[0].includes('-')) {
          this.defaultFilter = this.paramsBookable.sort.slice(1)
          this.sortOrder = true
        } else {
          this.defaultFilter = this.paramsBookable?.sort
          this.sortOrder = false
        }
      } else {
        this.defaultFilter = 'title'
      }

      if (this.paramsBookable?.search) {
        this.filter.search = this.paramsBookable.search
      }

      this.fetch(null, 1, this.paramsBookable)
    },

    filterList(filterValue) {
      this.fetch(null, filterValue)
    },

    activeAction() {
      this.fetch()
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetch(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.fetch()
    }, 600),

    async fetch(options, page = 1, paramsBookable = null) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalBookables
            : options.itemsPerPage
          : this.itemsPerPageTables === -1
          ? this.totalBookables
          : this.itemsPerPageTables,
        filter: this.defaultOption,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.clearBookables()
      this.loading = true
      await this.getBookables(paramsBookable || params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
          this.scrollToPosition()
        })
      this.setParams(params)
    },

    editItem(item) {
      this.setListBookParams(null)
      this.$router.push({
        name: 'bookable.details',
        params: { id: item.id },
      })
    },

    actionHandel(action) {
      if (this.selected.length === 0) {
        this.showSnackbar('Please select bookables', false)
        return
      }

      if (action === 'delete') {
        this.showBulkDeleteModal = true
      } else {
        this.bulkActions(action)
      }
    },

    async bulkActions(action) {
      this.loadingAction = true

      const formData = new FormData()
      formData.append('action', action)
      this.selected.forEach((property) => {
        formData.append('ids[]', property.id)
      })

      await this.bulkActionBookable(formData)
        .then(() => {
          this.showSnackbar('Bulk successfully updated!')
        })
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.loadingAction = false
        })
        .finally(() => {
          this.loadingAction = false
          this.selected = []
          this.clearBookables()
          this.fetch()
        })
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return
      this.scrollToPosition()
    },

    scrollToPosition() {
      const scrollPosition = this.scrollPosition

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },

    bulkPermission(value) {
      let allowAction = false

      switch (value) {
        case 'delete':
          allowAction = validatePermissions(
            [PERMISSION.BOOKABLES_DELETE],
            this.permissions
          )
          break
        default:
          break
      }
      return allowAction
    },
  },
}
</script>
<style lang="scss" scoped>
.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  &__option {
    width: 100%;
    max-width: 200px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }

    &__option {
      max-width: inherit;
      margin-bottom: 15px;
      margin-left: 0;
    }
  }
}

.v-list-item__title {
  cursor: pointer;
}

.v-data-table {
  ::v-deep .v-input.v-input--checkbox {
    .v-input__slot {
      margin-bottom: 0;
      justify-content: center;
    }

    .v-messages {
      display: none;
    }
  }
}
</style>
