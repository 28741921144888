<template>
  <div class="notification-preview">
    <div class="notification-preview__header">
      <h3 class="mb-4">Device preview</h3>
    </div>
    <div class="notification-preview__body">
      <div class="notification-preview__device">
        <div class="android">
          <div class="android__header">
            <div class="android__header-camera"></div>
          </div>
          <div class="android__body">
            <div class="android__noti">
              <div class="android__noti-content">
                <p class="font-weight-bold mb-0">{{ appName }} notification</p>
                <div class="truncate3-line">
                  {{ content }}
                </div>
              </div>
              <img class="android__noti-image" :src="logo" alt="" />
            </div>
          </div>
        </div>
        <span class="text-h6 font-weight-bold device-label">Android</span>

        <div class="ios">
          <div class="ios__header">
            <div class="ios__header-camera"></div>
          </div>
          <div class="ios__body">
            <div class="ios__body-bunny">
              <div class="conner-left"></div>
              <div class="conner-right"></div>
            </div>
            <div class="ios__noti">
              <div class="ios__noti-content">
                <p class="font-weight-bold mb-0">{{ appName }} notification</p>
                <div class="truncate3-line" style="max-width: 280px">
                  {{ content }}
                </div>
              </div>
              <img class="ios__noti-image" :src="logo" alt="" />
            </div>
          </div>
        </div>
        <span class="text-h6 font-weight-bold device-label">Apple</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NotificationReview',

  props: {
    content: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      activeTab: 'initial',
      tabs: [
        { name: 'Initial State', value: 'initial' },
        { name: 'Expanded view', value: 'expand' },
      ],
    }
  },

  computed: {
    ...mapGetters({
      appName: 'appName',
      logo: 'logo',
    }),
  },

  methods: {
    changeTab(tab) {
      this.activeTab = tab
    },
  },
}
</script>

<style lang="scss">
.notification-preview {
  width: 500px;
  margin-top: 45px;
  background: #fff;
  height: fit-content;

  @media (max-width: 768px) {
    max-width: 100%;
    min-width: 400px;
  }

  &__device {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
  }

  &__body {
    width: 500px;
    padding: 30px;
    background-color: var(--v-grey-lighten3);

    .android {
      margin-top: 20px;
      height: fit-content;
      display: flex;
      flex-direction: column;
      padding: 20px;
      padding-bottom: 0px;
      border: 4px solid #ddd;
      border-bottom: none;
      border-radius: 60px 60px 0 0;
      background: white;
      align-items: center;
      gap: 30px;
      width: 100%;

      &__header {
        width: 35%;
        height: 20px;
        border-radius: 30px;
        border: 2px solid #ddd;
        position: relative;
      }

      &__header-camera {
        position: absolute;
        right: -60px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid #ddd;
      }

      &__body {
        width: 100%;
        padding: 6px;
        background-color: grey;
        flex: 1;
      }

      &__noti {
        display: flex;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
        gap: 10px;
        align-items: center;
        padding: 10px;
        background-color: white;
      }

      &__noti-image {
        height: 50px;
        width: 50px;
      }
    }

    .ios {
      margin-top: 20px;
      height: fit-content;
      display: flex;
      flex-direction: column;
      padding: 20px 30px;
      padding-bottom: 0px;
      border: 4px solid #ddd;
      border-bottom: none;
      border-radius: 80px 80px 0 0;
      background: white;
      align-items: center;
      width: 100%;

      &__header {
        width: 20%;
        height: 10px;
        border-radius: 30px;
        border: 2px solid #ddd;
        position: relative;
      }

      &__header-camera {
        position: absolute;
        left: 140%;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid #ddd;
        bottom: -2px;

        @media (max-width: 768px) {
          right: -30px;
        }
      }

      &__body {
        width: 100%;
        padding: 8px;
        padding-top: 0;
        background-color: grey;
        flex: 1;
        border-radius: 50px 50px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        position: relative;
      }

      &__body-bunny {
        height: 35px;
        background: white;
        width: 60%;
        border-radius: 0 0 25px 25px;
        position: relative;
        @media (max-width: 768px) {
          top: -1px;
        }

        .conner-left {
          height: 10px;
          width: 5px;
          background-color: grey;
          border-radius: 0 30px 50px 0;
          position: absolute;
          left: -4px;
          right: -1px;

          @media (max-width: 768px) {
            top: 1px;
          }
        }

        .conner-right {
          height: 10px;
          width: 5px;
          background-color: grey;
          border-radius: 30px 0px 0px 50px;
          position: absolute;
          right: -4px;

          @media (max-width: 768px) {
            top: 1px;
          }
        }
      }

      &__noti {
        display: flex;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
        gap: 10px;
        align-items: center;
        padding: 10px;
        background-color: white;
        border-radius: 15px;
      }

      &__noti-image {
        height: 50px;
        width: 50px;
      }
    }

    .device-label {
      color: $darkGrey;
    }
  }

  .test-message-btn {
    width: 200px;
    height: 50px !important;
    border-radius: 10px;
  }
}

.truncate3-line {
  max-width: 300px;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
