<template>
  <v-autocomplete
    class="select-parcel-location"
    :chips="!!value.length"
    required
    v-bind="$attrs"
    :items="validItems"
    :item-value="valueColumn"
    :placeholder="placeholder"
    :item-text="textColumn"
    :value="value"
    :loading="loading || fetching"
    :disabled="disabled || fetching"
    @input="onChange"
  ></v-autocomplete>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: [Number, String],
      default: null,
    },

    items: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },

    placeholder: {
      type: String,
      default: 'Select Parcel Location',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    valueColumn: {
      type: String,
      default: 'id',
    },

    textColumn: {
      type: String,
      default: 'location',
    },

    preSelect: {
      type: Boolean,
      default: false,
    },

    preFetch: {
      type: Boolean,
      default: true,
    },

    buildingId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      fetching: false,
      parcel_locations: [],
    }
  },

  computed: {
    validItems() {
      let items = [
        ...this.items,
        ...(this.parcel_locations && this.parcel_locations.length
          ? this.parcel_locations
          : []),
      ]

      return !this.viaIndex
        ? items
        : items.map((item, i) => {
            return {
              index: i,
              ...item,
            }
          })
    },

    viaIndex() {
      return this.valueColumn.toLowerCase() === 'index'
    },
  },

  created() {
    if (this.preFetch) {
      this.fetch()
    }
  },

  watch: {
    buildingId(newValue, oldValue) {
      this.fetch()
    },
  },

  methods: {
    ...mapActions({
      getParcelLocationsByBuilding:
        'parcelLocation/getParcelLocationsByBuilding',
    }),

    async fetch() {
      this.fetching = true
      if (this.buildingId) {
        await this.getParcelLocationsByBuilding(this.buildingId)
          .then((data) => {
            this.parcel_locations = data
            this.$emit('populated', this.parcel_locations)
            let locationDefault = data.find((location) => location.is_default)

            // pre select default
            if (locationDefault && this.preSelect) {
              this.$emit('input', locationDefault.id)
            }
          })
          .finally(() => {
            this.fetching = false
          })
        this.fetching = false
      } else {
        this.fetching = false
      }
    },

    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>
