<template>
  <v-autocomplete
    class="select-multi-profile"
    :class="{
      'select-multi-profile-all': showSelectAll && isSelectAll,
    }"
    :chips="value.length > 0"
    :value="value"
    :items="validItems"
    :item-text="textColumn"
    :item-value="valueColumn"
    :placeholder="placeholder"
    :loading="loading || fetching"
    :disabled="disabled || fetching"
    :deletable-chips="deletableChips"
    v-bind="$attrs"
    @input="onChange"
    multiple
  >
    <template v-if="showSelectAll" v-slot:prepend-item>
      <v-list-item @click="toggleSelectAll(!isSelectAll)">
        <v-list-item-action>
          <v-checkbox :input-value="isSelectAll" color="primary"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>All</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </template>
  </v-autocomplete>
</template>
<script>
/**
 * ==================================================================================
 * Select Profiles
 * ==================================================================================
 **/

import bus from '@/store/modules/bus'
import { mapActions, mapMutations } from 'vuex'
import { find, findIndex } from 'lodash'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },

    items: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },

    placeholder: {
      type: String,
      default: 'Select Profiles',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    valueColumn: {
      type: String,
      default: 'id',
    },

    textColumn: {
      type: String,
      default: 'name',
    },

    preSelect: {
      type: Boolean,
      default: false,
    },

    preFetch: {
      type: Boolean,
      default: true,
    },

    deletableChips: {
      type: Boolean,
      default: true,
    },
    isSelectAll: {
      type: Boolean,
      default: false,
    },
    showSelectAll: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fetching: false,
      profiles: [],
    }
  },

  computed: {
    validItems() {
      let items = [
        ...this.items,
        ...(this.profiles && this.profiles.length ? this.profiles : []),
      ]

      items = items.filter(
        (item, index, self) => index === self.findIndex((t) => t.id === item.id)
      )

      return !this.viaIndex
        ? items
        : items.map((item, i) => {
            return {
              index: i,
              ...item,
            }
          })
    },

    viaIndex() {
      return this.valueColumn.toLowerCase() === 'index'
    },
  },

  created() {
    if (this.preFetch) {
      this.fetch()
    }

    bus.$on('profileCreated', this.addProfile)
    bus.$on('profileUpdated', this.updateProfile)
    bus.$on('profileRemoved', this.removeProfile)
  },

  methods: {
    ...mapActions({
      getProfiles: 'profile/getProfiles',
      getProfilesByBuildingId: 'profile/getProfilesByBuildingId',
    }),

    ...mapMutations({
      clearProfiles: 'profile/clearProfileList',
    }),

    async fetch() {
      if (this.fetching) return

      this.fetching = true
      this.clearProfiles()

      await this.getProfiles()
        .then((data) => {
          this.profiles = data
        })
        .finally(() => {
          this.fetching = false
        })
    },

    onChange(value) {
      this.$emit('input', value)
      this.$emit('select-all', false)
    },

    addProfile(profile) {
      const exist = find(this.profiles, { id: profile.id })
      if (!exist) {
        this.profiles.push(profile)
      }
    },

    updateProfile(profile) {
      const index = findIndex(this.profiles, { id: profile.id })
      if (index) {
        this.$set(this.profiles, index, profile)
      }
    },

    removeProfile(profile) {
      const index = findIndex(this.profiles, { id: profile.id })
      if (index) {
        this.profiles.splice(index)
      }
    },

    toggleSelectAll(value) {
      this.$emit('select-all', value)
      this.$emit('input', [])
    },
  },
}
</script>

<style lang="scss">
.select-multi-profile-all {
  input::placeholder {
    color: black !important;
  }
}
</style>
