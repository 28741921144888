<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold pl-0"
        >Parcel Location ({{ totalParcelLocations }})</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn
        v-if="hasCreatePermission"
        color="primary"
        class="appbar__btn"
        height="40px"
        :to="{ name: 'parcel.parcel-location.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Add Parcel Location
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="building-list"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-spacer />
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search parcel location"
          @input="search"
        ></v-text-field>
      </div>
      <v-data-table
        :headers="headers"
        :items="parcelLocations"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        :server-items-length="totalParcelLocations"
        @click:row="editItem"
        @update:options="fetchParcelLocations"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        :items-per-page.sync="itemsPerPageTables"
        @pagination="handleScrollAfterTableRender"
      >
        <template v-slot:item.is_active="{ item }">
          <div style="text-align: center">
            <v-checkbox v-model="item.is_active" disabled readonly />
          </div>
        </template>
        <template v-slot:item.is_default="{ item }">
          <div style="text-align: center">
            <v-checkbox v-model="item.is_default" disabled readonly />
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Building list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import { sortFilter, perPageFilter } from '@/utils/helpers'
import FilterTableMixin from '@/utils/mixins/FilterTable'

export default {
  name: 'BuildingsPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        add: mdiPlus,
      },
      defaultFilter: 'location',
      sortOrder: false,
      headers: [
        {
          text: 'Location',
          value: 'location',
        },
        {
          text: 'Building(s)',
          value: 'buildingName',
          sortable: false,
        },
        {
          text: 'Active',
          value: 'is_active',
        },
        {
          text: 'Default',
          value: 'is_default',
        },
        {
          text: 'Updated Date',
          value: 'updatedDate',
        },
        {
          text: 'Added Date',
          value: 'addedDate',
        },
      ],
      itemsPerPageTables: 25,
    }
  },

  created() {
    this.clearParcelLocations()
    this.fetchInitParams()
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      parcelLocations: (state) => state.parcelLocation.list,
      listMeta: (state) => state.parcelLocation.listMeta,
      permissions: (state) => state.auth.permissions,
      totalParcelLocations: (state) =>
        state.parcelLocation.totalParcelLocations,
      role: (state) => state.auth.role,
      paramsParcelLocation: (state) => state.parcelLocation.queryParams,
      scrollPosition: (state) => state.parcelLocation.scrollPosition,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },

    hasCreatePermission() {
      return validatePermissions(
        [PERMISSION.BUILDINGS_CREATE],
        this.permissions
      )
    },
  },

  methods: {
    ...mapActions({
      getParcelLocations: 'parcelLocation/getParcelLocations',
    }),

    ...mapMutations({
      clearParcelLocations: 'parcelLocation/clearParcelLocationList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'parcelLocation/setQueryParams',
      setScrollPosition: 'parcelLocation/setScrollPosition',
    }),

    filterList(filterValue) {
      this.fetchParcelLocations(null, filterValue)
    },

    search: debounce(function () {
      this.fetchParcelLocations()
    }, 600),

    fetchInitParams() {
      if (this.paramsParcelLocation?.sort) {
        if (this.paramsParcelLocation.sort[0].includes('-')) {
          this.defaultFilter = this.paramsParcelLocation.sort.slice(1)
          this.sortOrder = true
        } else {
          this.defaultFilter = this.paramsParcelLocation?.sort
          this.sortOrder = false
        }
      } else {
        this.defaultFilter = 'location'
      }

      if (this.paramsParcelLocation?.itemsPerPage) {
        const { itemsPerPage } = this.paramsParcelLocation
        this.itemsPerPageTables = [25, 50, 100].includes(itemsPerPage)
          ? itemsPerPage
          : -1
      }

      if (this.paramsParcelLocation?.search) {
        this.filter.search = this.paramsParcelLocation.search
      }

      this.fetchParcelLocations(null, 1, this.paramsParcelLocation)
    },

    async fetchParcelLocations(options, page = 1, paramsParcelLocation = null) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: perPageFilter(
          options,
          this.totalParcelLocations,
          this.itemsPerPageTables
        ),
        sort: sortFilter(
          options,
          this.defaultFilter,
          this.sortOrder,
          'location'
        ),
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.clearParcelLocations()
      this.loading = true
      await this.getParcelLocations(paramsParcelLocation || params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
          this.scrollToPosition()
        })
      this.setParams(params)
    },

    editItem(item) {
      this.$router.push({
        name: 'parcel.parcel-location.details',
        params: { id: item.id },
      })
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0 || pagination.pageStop === 0) return
      this.scrollToPosition()
    },

    scrollToPosition() {
      const scrollPosition = this.scrollPosition

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}

.v-list-item__title {
  cursor: pointer;
}
</style>
