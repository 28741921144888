<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-file-document-multiple</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Documents ({{ totalFolders }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        v-if="hasPermissions"
        color="primary"
        class="appbar__btn"
        height="40px"
        :to="{ name: 'hub.documents.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Create Documents
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="document-list"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-spacer />
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          :clearable="!loading"
          background-color="grey lighten-3"
          label="Search building and folder name"
          @input="search"
        ></v-text-field>

        <!-- <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-10 bulk-btn"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              :loading="loadingAction"
              height="40px"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title
                @click="item.permission && actionHandel(item.value)"
                :style="{
                  cursor: item.permission ? 'pointer' : 'not-allowed',
                  color: item.permission ? 'black' : 'gray',
                }"
                >{{ item.label }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu> -->
      </div>
      <v-data-table
        :headers="headers"
        :items="folders"
        v-model="selected"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        :loading="loading"
        @click:row="editItem"
        @update:options="fetch"
        :server-items-length="totalFolders"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        :items-per-page.sync="itemsPerPageTables"
        @pagination="handleScrollAfterTableRender"
      >
        <template v-slot:item.building="{ item }">
          <div class="text-truncate" style="max-width: 200px">
            {{ item.buildings.map((building) => building.name).join(', ') }}
          </div>
        </template>
        <template v-slot:item.is_active="{ item }">
          <v-checkbox v-model="item.is_active" readonly class="mt-0 mb-0" />
        </template>
        <template v-slot:item.user="{ item }">
          <div v-if="item.user">{{ item.user.full_name }}</div>
        </template>
        <template v-slot:item.documents="{ item }">
          <div v-if="item.documents">{{ item.documents.length }}</div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div v-if="item.addedDate">{{ item.addedDate }}</div>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <div v-if="item.updatedDate">{{ item.updatedDate }}</div>
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(folders.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
    <ConfirmModal
      v-model="showBulkDeleteModal"
      title=""
      :message="messageDelete"
      @cancel="showBulkDeleteModal = false"
      @confirm="
        bulkActions('delete')
        showBulkDeleteModal = false
      "
      confirmText="Yes"
      cancelText="No"
    ></ConfirmModal>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Hub FAQ list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { messageConfirmDelete } from '@/utils/helpers'

export default {
  name: 'DocumentsPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
    ConfirmModal,
  },

  data() {
    return {
      loading: false,
      loadingAction: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      selected: [],
      defaultFilter: 'name',
      sortOrder: false,
      filterBy: [
        {
          value: 'name',
          label: 'Folder name',
        },
        {
          value: 'created_at',
          label: 'Added date',
        },
      ],
      headers: [
        {
          text: 'Folder Name',
          value: 'name',
        },
        {
          text: 'Buildings',
          value: 'building',
        },
        {
          text: 'Documents',
          value: 'documents',
        },
        {
          text: 'Active',
          value: 'is_active',
        },
        {
          text: 'Added By',
          value: 'user',
        },
        {
          text: 'Added date',
          value: 'created_at',
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
          permission: validatePermissions(
            [PERMISSION.DOCUMENTS_DELETE],
            this.permissions
          ),
        },
      ],
      itemsPerPageTables: 25,
      showBulkDeleteModal: false,
    }
  },

  created() {
    this.fetchInitParams()
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      folders: (state) => state.folder.list,
      totalFolders: (state) => state.folder.totalFolders,
      permissions: (state) => state.auth.permissions,
      paramsDocument: (state) => state.folder.queryParams,
      scrollPosition: (state) => state.folder.scrollPosition,
    }),

    hasPermissions() {
      return validatePermissions(
        [PERMISSION.DOCUMENTS_CREATE, PERMISSION.DOCUMENT_DELETE],
        this.permissions
      )
    },

    messageDelete() {
      return messageConfirmDelete(this.selected.length)
    },
  },

  methods: {
    ...mapActions({
      getFolders: 'folder/getFolders',
      bulkActionFolder: 'folder/bulkActionFolder',
    }),

    ...mapMutations({
      clearFolders: 'folder/clearFolderList',
      setParams: 'folder/setQueryParams',
      setScrollPosition: 'folder/setScrollPosition',
    }),

    fetchInitParams() {
      if (this.paramsDocument?.sort) {
        if (this.paramsDocument.sort[0].includes('-')) {
          this.defaultFilter = this.paramsDocument.sort.slice(1)
          this.sortOrder = true
        } else {
          this.defaultFilter = this.paramsDocument?.sort
          this.sortOrder = false
        }
      } else {
        this.defaultFilter = 'created_at'
      }

      if (this.paramsDocument?.itemsPerPage) {
        const { itemsPerPage } = this.paramsDocument
        this.itemsPerPageTables = [25, 50, 100].includes(itemsPerPage)
          ? itemsPerPage
          : -1
      }

      if (this.paramsDocument?.search) {
        this.filter.search = this.paramsDocument.search
      }

      this.fetch(null, 1, this.paramsDocument)
    },

    filterList(filterValue) {
      this.fetch()
    },

    search: debounce(function () {
      this.fetch()
    }, 600),

    async fetch(options, page = 1, paramsDocument = null) {
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalFolders
            : options.itemsPerPage
          : this.itemsPerPageTables === -1
          ? this.totalFolders
          : this.itemsPerPageTables,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.clearFolders()
      this.loading = true
      await this.getFolders(paramsDocument || params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
          this.scrollToPosition()
        })
      this.setParams(params)
    },
    editItem(item) {
      this.$router.push({
        name: 'hub.documents.details',
        params: { id: item.id },
      })
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0 || pagination.pageStop === 0) return
      this.scrollToPosition()
    },

    scrollToPosition() {
      const scrollPosition = this.scrollPosition

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },

    actionHandel(action) {
      if (this.selected.length === 0) {
        this.showSnackbar('Please select folders', false)
        return
      }

      if (action === 'delete') {
        this.showBulkDeleteModal = true
      } else {
        this.bulkActions(action)
      }
    },

    async bulkActions(action) {
      this.loadingAction = true

      const formData = new FormData()
      formData.append('action', action)
      this.selected.forEach((property) => {
        formData.append('folder_ids[]', property.id)
      })

      await this.bulkActionFolder(formData)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.loadingAction = false
        })
        .finally(() => {
          this.loadingAction = false
          this.selected = []
          this.clearFolders()
          this.fetch()
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}
.v-list-item__title {
  cursor: pointer;
}
</style>
