<template>
  <div class="asset-details py-8">
    <AssetAppbar />

    <v-row class="mx-4">
      <v-col cols="12" class="ma-0 pa-0">
        <v-row>
          <v-col cols="6" md="5">
            <ViewingTitle :title="displayName" class="asset-details__title" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="ma-0 pa-0">
        <v-col cols="6" md="5" class="text-end">
          <DeleteOrRestore
            v-if="hasDeletePermission"
            :model="asset"
            name="Asset"
            redirect="/maintenance/assets"
          />
        </v-col>
      </v-col>
      <v-col cols="12" class="ma-0 pa-0">
        <div class="d-flex mb-6">
          <v-tabs hide-slider class="asset-info-tabs">
            <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
              {{ item.tab }}
            </v-tab>

            <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
              <v-divider class="asset-details__divider"></v-divider>

              <AssetForm class="asset-details__form" :asset="asset" />
            </v-tab-item>
          </v-tabs>
        </div>
      </v-col>
    </v-row>

    <Loading :value="loading" />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * View Asset
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import AssetAppbar from '@/views/Home/Maintenance/Assets/components/AssetAppbar.vue'
import AssetForm from '@/views/Home/Maintenance/Assets/components/AssetForm.vue'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore/index.vue'

export default {
  name: 'AssetDetails',

  components: {
    DeleteOrRestore,
    ViewingTitle,
    AssetAppbar,
    AssetForm,
    Loading,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      asset: (state) => state.asset.assetDetails,
      permissions: (state) => state.auth.permissions,
    }),

    displayName() {
      return this.asset ? this.asset.name : null
    },

    hasDeletePermission() {
      return validatePermissions([PERMISSION.ASSETS_DELETE], this.permissions)
    },
  },

  methods: {
    ...mapActions({
      getAssetDetails: 'asset/getAssetDetails',
    }),

    ...mapMutations({
      clearAssetDetails: 'asset/clearAssetDetails',
    }),

    async getAsset() {
      this.loading = true
      await this.getAssetDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getAsset()
  },

  destroyed() {
    this.clearAssetDetails()
  },

  watch: {
    $route() {
      this.getAsset()
    },
  },
}
</script>

<style lang="scss" scoped>
.asset-details {
  &__title {
    padding-right: 10px;
  }

  &__divider {
    max-width: 400px;
  }

  &__uploader {
    //
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
