<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-package</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Parcels ({{ totalParcels }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <div class="d-flex ga-4">
        <v-btn
          v-if="hasCreatePermission"
          color="primary"
          class="appbar__btn mr-4"
          height="40px"
          :style="{ height: '40px', width: 'fit-content', marginBottom: '0' }"
          :to="{ name: 'parcel.list.create' }"
        >
          <v-icon class="mr-2">{{ icons.add }}</v-icon>
          Add Parcel
        </v-btn>

        <BulkAction
          :sub-items="actionsWithPermissions"
          @selected="actionHandle"
        />

        <v-tooltip>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#383d3f"
              v-bind="attrs"
              icon
              right
              v-on="on"
              :loading="loading || loadingAction"
              height="40px"
              @click="exportList"
            >
              <v-icon>mdi-export-variant</v-icon>
            </v-btn>
          </template>
          <span>Export</span>
        </v-tooltip>
      </div>
    </v-app-bar>

    <div
      class="mx-4"
      id="parcels-list"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-tabs v-model="defaultStatus">
          <v-tab
            class="pa-3"
            v-for="item in tabItems"
            :key="item.index"
            :ref="item.index"
            :disabled="defaultStatus !== item.index && loading"
            @click="handleTabClick(item.index)"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <v-spacer />
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search parcels"
          @input="search"
        ></v-text-field>
      </div>
      <v-data-table
        :headers="headers"
        :items="parcels"
        v-model="selected"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        show-select
        :server-items-length="totalParcels"
        @update:options="fetch"
        @click:row="editItem"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        :items-per-page.sync="itemsPerPageTables"
        @pagination="handleScrollAfterTableRender"
      >
        <template v-slot:item.property="{ item }">
          {{ item.propertyName }}
        </template>
        <template v-slot:item.recipient="{ item }">
          {{ item.userName }}
        </template>
        <template v-slot:item.building="{ item }">
          {{ item.buildingName }}
        </template>
        <template v-slot:item.type="{ item }">
          {{ item.typeName }}
        </template>
        <template v-slot:item.parcel_id="{ item }">
          <div :class="{ 'text-light-grey opacity-60': !item.parcel_id }">
            {{ item.parcel_id ? item.parcel_id : 'No ID' }}
          </div>
        </template>
        <template v-slot:item.addedBy="{ item }">
          {{ item.addByName }}
        </template>
        <template v-slot:item.notified="{ item }">
          <v-checkbox
            v-model="item.notified"
            disabled
            readonly
            class="mt-0 mb-0"
          />
        </template>
        <template v-slot:item.day_held="{ item }">
          {{ getDaysFromCreatedAt(item.created_at) }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.addedDate }}
        </template>
      </v-data-table>
      <ConfirmModal
        v-model="showBulkDeleteModal"
        title=""
        :message="messageDelete"
        @cancel="showBulkDeleteModal = false"
        @confirm="
          bulkActions('delete')
          showBulkDeleteModal = false
        "
        confirmText="Yes"
        cancelText="No"
      ></ConfirmModal>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Parcel list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import ConfirmModal from '@/components/modals/ConfirmModal'
import BulkAction from '@/components/common/BulkAction'

import {
  sortFilter,
  perPageFilter,
  messageConfirmDelete,
} from '@/utils/helpers'

export default {
  name: 'ParcelsPage',
  mixins: [FilterTableMixin, ControlsMixin, SnackbarMixin, ErrorHandlerMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
    ConfirmModal,
    BulkAction,
  },

  data() {
    return {
      loading: false,
      loadingAction: false,
      filter: {
        search: '',
      },
      icons: {
        add: mdiPlus,
      },
      defaultFilter: 'created_at',
      sortOrder: false,
      defaultStatus: 0,
      tabItems: [
        { tab: 'Pending ', index: 0 },
        { tab: 'Collected', index: 1 },
        { tab: 'All', index: 2 },
      ],
      headers: [
        {
          text: 'Property Name',
          value: 'property',
        },
        {
          text: 'Recipient',
          value: 'recipient',
        },
        {
          text: 'Building',
          value: 'building',
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'Parcel ID',
          value: 'parcel_id',
        },
        {
          text: 'Notified',
          value: 'notified',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Day Held',
          value: 'day_held',
          sortable: false,
        },
        {
          text: 'Added By',
          value: 'addedBy',
        },
        {
          text: 'Added Date',
          value: 'created_at',
        },
      ],
      actions: [
        {
          value: 'collected',
          label: 'Mark as Collected',
          permission: false,
        },
      ],
      selected: [],
      itemsPerPageTables: 25,
      showBulkDeleteModal: false,
      exportParams: null,
    }
  },

  created() {
    this.fetchInitParams()
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      parcels: (state) => state.parcel.list,
      permissions: (state) => state.auth.permissions,
      totalParcels: (state) => state.parcel.totalParcels,
      paramsParcel: (state) => state.parcel.queryParams,
      scrollPosition: (state) => state.parcel.scrollPosition,
    }),

    hasCreatePermission() {
      return validatePermissions([PERMISSION.PARCEL_CREATE], this.permissions)
    },

    hasUpdatePermission() {
      return validatePermissions([PERMISSION.PARCEL_UPDATE], this.permissions)
    },

    actionsWithPermissions() {
      return this.actions.map((action) => ({
        ...action,
        permission: this.bulkPermission(action.value),
      }))
    },

    messageDelete() {
      return messageConfirmDelete(this.selected.length)
    },
  },

  methods: {
    ...mapActions({
      getParcels: 'parcel/getParcels',
      bulkActionParcel: 'parcel/bulkActionParcel',
      exportToExcel: 'parcel/exportToExcel',
    }),

    ...mapMutations({
      clearParcels: 'parcel/clearParcelList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'parcel/setQueryParams',
      setScrollPosition: 'parcel/setScrollPosition',
    }),

    handleTabClick(status) {
      this.defaultStatus = status
      this.defaultFilter = 'created_at'
      this.itemsPerPageTables = 25
      this.fetch()
    },

    fetchInitParams() {
      if (this.paramsParcel?.filter) {
        this.defaultStatus = this.paramsParcel.filter
      }

      if (this.paramsParcel?.itemsPerPage) {
        const { itemsPerPage } = this.paramsParcel
        this.itemsPerPageTables = [25, 50, 100].includes(itemsPerPage)
          ? itemsPerPage
          : -1
      }

      if (this.paramsParcel?.sort) {
        if (this.paramsParcel.sort[0].includes('-')) {
          this.defaultFilter = this.paramsParcel.sort.slice(1)
          this.sortOrder = true
        } else {
          this.defaultFilter = this.paramsParcel?.sort
          this.sortOrder = false
        }
      } else {
        this.defaultFilter = 'created_at'
      }

      if (this.paramsParcel?.search) {
        this.filter.search = this.paramsParcel.search
      }

      this.fetch(null, 1, this.paramsParcel)
    },

    filterList(filterValue) {
      this.fetch(null, filterValue)
    },

    search: debounce(function () {
      this.fetch()
    }, 600),

    async fetch(options, page = 1, paramsParcel = null) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: perPageFilter(
          options,
          this.totalParcels,
          this.itemsPerPageTables
        ),
        filter: this.defaultStatus,
        sort: sortFilter(
          options,
          this.defaultFilter,
          this.sortOrder,
          'created_at'
        ),
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.clearParcels()
      this.loading = true
      await this.getParcels(paramsParcel || params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
          this.scrollToPosition()
          this.setParams(params)
          this.exportParams = params
        })
    },
    editItem(item) {
      this.$router.push({
        name: 'parcel.list.details',
        params: { id: item.id },
      })
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return
      this.scrollToPosition()
    },

    scrollToPosition() {
      const scrollPosition = this.scrollPosition

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },

    actionHandle(action) {
      if (this.selected.length === 0) {
        this.showSnackbar('Please select parcels', false)
        return
      }

      if (action === 'delete') {
        this.showBulkDeleteModal = true
      } else {
        this.bulkActions(action)
      }
    },

    async bulkActions(action) {
      this.loadingAction = true

      const formData = new FormData()
      formData.append('action', action)
      this.selected.forEach((property) => {
        formData.append('parcel_ids[]', property.id)
      })

      await this.bulkActionParcel(formData)
        .then(() => {
          this.showSnackbar('Bulk successfully updated!')
        })
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.loadingAction = false
        })
        .finally(() => {
          this.loadingAction = false
          this.selected = []
          this.clearParcels()
          this.fetch()
        })
    },

    bulkPermission(value) {
      let allowAction = false
      switch (value) {
        case 'delete':
          allowAction = validatePermissions(
            [PERMISSION.PARCEL_DELETE],
            this.permissions
          )
          break
        case 'collected':
          allowAction = validatePermissions(
            [PERMISSION.PARCEL_UPDATE],
            this.permissions
          )
          break
        default:
          break
      }
      return allowAction
    },

    async exportList() {
      this.setLoadingBuilding(true)
      this.loading = true

      await this.exportToExcel(this.exportParams)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'parcel_list.xlsx')
          document.body.appendChild(link)

          link.click()
          this.showSnackbar('File successfully downloaded')
          this.setLoadingBuilding(false)
        })
        .catch((err) => {
          this.showSnackbar(
            this.getErrorMessage(err, 'Oops! Something went wrong'),
            false
          )
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },

    getDaysFromCreatedAt(createdAt) {
      const createdDate = new Date(createdAt)
      const currentDate = new Date()

      // Calculate the difference in milliseconds
      const diffTime = currentDate - createdDate

      // Convert milliseconds to days
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

      return diffDays
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input.v-input--checkbox {
  justify-content: center;
  margin: 0;

  .v-input__control {
    width: auto;
    flex-grow: 0;
  }

  .v-input__slot {
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}
::v-deep .main-appbar {
  height: auto !important;
}

::v-deep .v-toolbar__content {
  flex-wrap: wrap;
  height: auto !important;
  gap: 10px;
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}

.opacity-60 {
  opacity: 60%;
}

.v-list-item__title {
  cursor: pointer;
}

.v-data-table {
  ::v-deep .v-input.v-input--checkbox {
    .v-input__slot {
      margin-bottom: 0;
      justify-content: center;
    }

    .v-messages {
      display: none;
    }
  }
}
</style>
