var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{staticClass:"main-appbar",attrs:{"flat":"","color":"transparent"}},[_c('app-bar-nav-icon'),_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-home-group")]),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"chip","transition":"fade-transition"}}):_c('v-toolbar-title',{staticClass:"headline font-weight-bold"},[_vm._v("Properties ("+_vm._s(_vm.totalProperties)+")")]),_c('v-spacer'),_c('div',{staticClass:"property-details__status_action"},[(_vm.hasCreatePermission)?_c('v-btn',{staticClass:"appbar__btn",attrs:{"color":"primary","height":"40px","to":{ name: 'property.create' }}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.icons.add))]),_vm._v(" Create Property ")],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({style:({ backgroundColor: '#606060' }),attrs:{"loading":_vm.loadingAction,"height":"40px","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.icons.chevronDown))]),_vm._v(" Bulk Updates ")],1)]}}])},[_c('v-list',_vm._l((_vm.actionsWithPermissions),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',{style:({
                cursor: item.permission ? 'pointer' : 'not-allowed',
                color: item.permission ? 'black' : 'gray',
              }),on:{"click":function($event){item.permission && _vm.actionHandel(item.value)}}},[_vm._v(_vm._s(item.label))])],1)}),1)],1),_c('v-btn',{staticClass:"appbar__btn font-weight-bold",attrs:{"height":"40px"},on:{"click":_vm.exportList}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-export-variant")]),_vm._v(" EXPORT ")],1)],1)],1),_c('div',{staticClass:"mx-4",attrs:{"id":"property-list","infinite-scroll-disabled":_vm.loading,"infinite-scroll-distance":"100"}},[_c('div',{staticClass:"appbar mt-7 mb-3"},[_c('v-tabs',{model:{value:(_vm.defaultStatus),callback:function ($$v) {_vm.defaultStatus=$$v},expression:"defaultStatus"}},_vm._l((_vm.tabItems),function(item){return _c('v-tab',{key:item.value,ref:item.value,refInFor:true,staticClass:"pa-3",attrs:{"disabled":_vm.defaultStatus !== item.value && _vm.loading},on:{"click":function($event){return _vm.handleTabClick(item.value)}}},[_vm._v(" "+_vm._s(item.tab)+" ")])}),1),_c('v-spacer'),_c('v-text-field',{ref:"searchField",staticClass:"appbar__filter",attrs:{"flat":"","hide-details":"","solo":"","rounded":"","clearable":"","background-color":"grey lighten-3","label":"Search title or building"},on:{"input":_vm.search},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.properties,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [25, 50, 100, -1],
      },"show-select":"","server-items-length":_vm.totalProperties,"sort-by":_vm.defaultFilter,"sort-desc":_vm.sortOrder,"items-per-page":_vm.itemsPerPageTables},on:{"click:row":_vm.editItem,"update:options":_vm.fetch,"update:sortBy":function($event){_vm.defaultFilter=$event},"update:sort-by":function($event){_vm.defaultFilter=$event},"update:sortDesc":function($event){_vm.sortOrder=$event},"update:sort-desc":function($event){_vm.sortOrder=$event},"update:itemsPerPage":function($event){_vm.itemsPerPageTables=$event},"update:items-per-page":function($event){_vm.itemsPerPageTables=$event},"pagination":_vm.handleScrollAfterTableRender},scopedSlots:_vm._u([{key:"item.building",fn:function({ item }){return [(item.buildingName)?_c('div',[_vm._v(_vm._s(item.buildingName))]):_vm._e()]}},{key:"item.status",fn:function({ item }){return [_c('v-checkbox',{staticClass:"mt-0 mb-0",attrs:{"disabled":"","readonly":""},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.created_at",fn:function({ item }){return [(item.addedDate)?_c('div',[_vm._v(_vm._s(item.addedDate))]):_vm._e()]}},{key:"item.created_by",fn:function({ item }){return [(item.createdBy)?_c('div',[_vm._v(_vm._s(item.createdBy))]):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('ConfirmModal',{attrs:{"title":"","message":_vm.messageDelete,"confirmText":"Yes","cancelText":"No"},on:{"cancel":function($event){_vm.showBulkDeleteModal = false},"confirm":function($event){_vm.bulkActions('delete')
      _vm.showBulkDeleteModal = false}},model:{value:(_vm.showBulkDeleteModal),callback:function ($$v) {_vm.showBulkDeleteModal=$$v},expression:"showBulkDeleteModal"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }